import React, { useEffect, useState } from 'react'
import ProcessModelSecCard from '../../common/Cards/ProcessModelSecCard'
import { ProcessModelSecImgs, TestimonialImgs } from '../../../static/constants/imgConstants/imgConstants';

function ProcessModelSec() {
    const [selectId, setSelectId] = useState("");

    const [data] = useState([
        {
            id: "1",
            isFocused: false,
            title: `Discovery and\nRequirement Gathering`,
            bgImg: ProcessModelSecImgs.ProcessModelImg1,
        },
        {
            id: "2",
            isFocused: false,
            title: `Analysis and\nPlanning:`,
            bgImg: ProcessModelSecImgs.ProcessModelImg2,
        },
        {
            id: "3",
            isFocused: false,
            title: `Design and\nPrototyping`,
            bgImg: ProcessModelSecImgs.ProcessModelImg2,
        },
        {
            id: "4",
            isFocused: false,
            title: `Development and\nImplementation`,
            bgImg: ProcessModelSecImgs.ProcessModelImg2,
        },
        {
            id: "5",
            isFocused: false,
            title: `Testing and Quality\nAssurance`,
            bgImg: ProcessModelSecImgs.ProcessModelImg3,
        },
        {
            id: "6",
            isFocused: false,
            title: `Deployment And Post-\nDeployment Support`,
            bgImg: ProcessModelSecImgs.ProcessModelImg4,
        },
        {
            id: "7",
            isFocused: false,
            title: `Ongoing Support and\nMaintenance`,
            bgImg: ProcessModelSecImgs.ProcessModelImg4,
        },
        {
            id: "8",
            isFocused: false,
            title: `Training and\nDocumentation`,
            bgImg: ProcessModelSecImgs.ProcessModelImg4,
        },
    ]);

    const handleModalClick = (id) => {
        setSelectId(id)
    }

    return (
        <div className='process-model-sec w-full relative'>
            <div className="absolute w-[14.5rem] h-[14.5rem] left-0 bottom-0">
                <img src={TestimonialImgs.LeftQuadrant} alt="" className="w-full h-full" />
            </div>
            <div className='container-lg'>
                <div className='process-model-sec-container flex flex-col justify-center items-start h-[114rem] xs:h-[140rem] sm:h-[140rem] md:h-[160rem] lg:h-[88rem] '>
                    <div className='flex flex-col justify-start items-start '>
                        <div className={`process-model-sec-header flex justify-start items-start text-6xl font-[700]`}>
                            <h1 className='text-[#001736] mr-[1.2rem]'>
                                Process
                            </h1>
                            <h1 className='text-[#6842E5]'>
                                Model
                            </h1>
                        </div>
                        <h1 className='text-[#3C436A] text-3xl font-[300] my-[3rem]'>
                            From Imagination to Realization and Beyond: Unveiling Our Process Model for Continuous Support and Future Enhancements.
                        </h1>
                    </div>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-10'>
                        {data.map((item) => {
                            return (
                                <div className="" onClick={() => handleModalClick(item.id)}>
                                    <ProcessModelSecCard
                                        key={item.id}
                                        id={item.id}
                                        isFocused={item.id === selectId ? true : false}
                                        setSelectId={setSelectId}
                                        title={item.title}
                                        bgImg={item.bgImg}
                                    />
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcessModelSec
