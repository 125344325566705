import React from 'react'
import { NavbarImgs, SocialIconImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router-dom';

function FooterMain() {

    const navigate = useNavigate();

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className='footer-main bg-[#130835] w-full'>
            <div className='flex flex-col sm:flex-row justify-between items-start container-lg h-[40rem] sm:h-[44rem] pt-[8rem] sm:pt-[12rem]'>
                <div className='h-full flex flex-col justify-start items-start'>
                    <div 
                        className='footer-infominez-logo w-[23rem] h-[4.5rem] mt-4 mb-10 cursor-pointer'
                        onClick={() => {navigate("/")}}
                    >
                        <img 
                            className='w-full h-full'
                            src={NavbarImgs.InfominezLogoLight}
                            alt='logo'
                        />
                    </div>
                    {/* <p className='text-left text-sm text-[#FFFFFF]'>
                        <pre className='text-Montserrat-sans-serif'>
                            {`We are an agency specializing in web design &\nweb automation since 2013`}
                        </pre>
                    </p> */}
                </div>
                <div className='flex justify-between lg:justify-around items-start w-full sm:ml-[2rem]'>
                    <div className=' flex flex-col justify-center items-start text-base'>
                        <h5 className=' text-left text-[#12D4FF] mb-6'>
                            Explore
                        </h5>
                        <a 
                            className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                            href='/#'
                        >
                            Home
                        </a>
                        <a 
                            className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                            href='/#about-us'
                        >
                            About
                        </a>
                    </div>
                    <div className=' flex flex-col justify-center items-start text-base'>
                        <h5 className=' text-left text-[#12D4FF] mb-6'>
                            Support
                        </h5>
                        <a 
                            className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                            href='/contact-us'
                        >
                            Contact Us
                        </a>
                    </div>
                    <div className=' flex flex-col justify-center items-start text-base'>
                        <h5 className=' text-left text-[#12D4FF] mb-6'>
                            Social
                        </h5>
                        <div 
                            className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                            onClick={()=> handleClick('https://www.linkedin.com/company/infominez/mycompany/')}
                        >
                            <div className='social-icons w-[2.1rem] h-[2rem] mr-3'>
                                <img 
                                    className='w-full h-full'
                                    src={SocialIconImgs.LinkedinIconWhiteImg}
                                />
                            </div>
                            Linkedin
                        </div>
                        <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={()=> handleClick('https://www.facebook.com/infominez/')}
                        >
                            <div className='social-icons w-[2.1rem] h-[2rem] mr-3'>
                                <img 
                                    className='w-full h-full'
                                    src={SocialIconImgs.FbIconWhiteImg}
                                />
                            </div>
                            Facebook
                        </div>
                        <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={()=> handleClick('https://twitter.com/infominez')}
                                >
                            <div className='social-icons w-[2.1rem] h-[2rem] mr-3'>
                                <img 
                                    className='w-full h-full'
                                    src={SocialIconImgs.TwitterIconWhiteImg}
                                />
                            </div>
                            Twitter
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterMain
