import React from 'react'

function ServicesDetailsOfferings({ servicesSubHeading, engineeringService, servicesHeading }) {
    return (
        <div id="services-details-offerings" className='services-details-offerings relative w-full py-[5rem]'>
            <div className='industries=details-service-offerings-content container-lg flex flex-col justify-center items-start'>
                <h1 className='text-[5rem] text-[#5C33A5] font-[700] mb-[1.2rem]'>
                    {servicesHeading}
                </h1>
                <h1 className='text-7xl text-[#45484D] font-[400] mb-[7.5rem]'>
                    {servicesSubHeading}
                </h1>
                <div className='grid grid-cols-2 gap-x-[10rem] gap-y-[4rem]'>
                    {
                        engineeringService?.map((item, i) => {
                            return (
                                <div className='flex justify-center items-start'>
                                    <div className='advantage-with-us w-[10.6rem] h-[14.5rem] mr-[1.6rem] rounded-[1rem] shadow-[0_.4rem_1.4rem_0_rgba(0,0,0,0.25)]'>
                                        <img 
                                            className='w-full h-full rounded-[1rem]'
                                            src={item.icon}
                                        />
                                    </div>
                                    <div className='flex flex-col justify-start items-start w-[86%]'>
                                        <h5 className='text-[2.5rem] font-[600] text-[#29134F]'>
                                            {item.title} 
                                        </h5>
                                        <h5 className='text-2xl font-[400] text-[#95999F]'>
                                            {item.text}
                                        </h5>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ServicesDetailsOfferings
