import React from 'react'

function SectionHeaders({ contentPrimary, contentSecondary, classes }) {
    return (
        <div className={`section-headers flex ${classes}`}>
            <h1 className='text-[#000000] mr-[.2rem]'>
                {contentPrimary}
            </h1>
            <h1 className='text-[#6842E5]'>
                {contentSecondary}
            </h1>
        </div>
    )
}

export default SectionHeaders
