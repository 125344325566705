import React, { useState } from 'react'
import { ContactUsImgs } from '../../../static/constants/imgConstants/imgConstants';
import { handleContactUs } from '../../../services/contactUsServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function DropUsALine() {

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidMobile, setIsValidMobile] = useState(true);

    return (
        <div className='drop-us-a-line w-full bg-[#F7F7FF] flex'>
            <div className='w-full lg:w-[50%] flex flex-col justify-center items-center py-[8rem] xl:py-[24rem]'>
                <div className='w-[80%] sm:w-[60%] flex flex-col justify-center items-center'>
                    <h1 className='text-[4.5rem] text-[#3C436A] font-[300]'>
                        Drop Us a Line
                    </h1>
                    <div className='flex flex-col justify-center items-center mt-[1.4rem] mb-[4rem]'>
                        <h1 className='text-[2.4rem] text-[#404040] text-center'>
                            It will have a ‘Get in touch’ link to it
                        </h1>
                    </div>
                </div>
                <div className={`w-[80%] sm:w-[60%] h-[6.5rem] mb-[1.4rem] ${!isValidName ? "border-[.1rem] border-[#FF2323]" : ""}`}>
                    <input
                        className='w-full h-full px-8 text-2xl'
                        type="text"
                        placeholder="Your Name*"
                        value={name}
                        onChange={(e) => {
                            let nameReg = /[^a-z]/gi;
                            if (!nameReg.test(e.target.value)) {
                                // setValue(e.target.value);  
                                setName(e.target.value)
                            } else {
                                return
                            }
                        }}
                    />
                </div>
                <div className={`w-[80%] sm:w-[60%] h-[6.5rem] mb-[1.4rem] ${!isValidEmail ? "border-[.1rem] border-[#FF2323]" : ""}`}>
                    <input
                        className='w-full h-full px-8 text-2xl'
                        type="email"
                        placeholder="Email*"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </div>
                <div className={`w-[80%] sm:w-[60%] h-[6.5rem] mb-[1.4rem] ${!isValidMobile ? "border-[.1rem] border-[#FF2323]" : ""}`}>
                    <input
                        className='w-full h-full px-8 text-2xl'
                        type="number"
                        placeholder="Phone No*"
                        value={mobile}
                        onChange={(e) => {
                            if (e.target.value.length <= 10) {
                                setMobile(e.target.value.replace(/[^0-9]/g, ""));
                            }
                        }}
                    />
                </div>
                <div className='w-[80%] sm:w-[60%] mb-[1.4rem]'>
                    <textarea
                        className='w-full h-full p-8 text-2xl focus:outline-0'
                        rows="4" cols="50"
                        type="text"
                        placeholder="Enter a message"
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                    />
                </div>
                <button
                    className='w-[80%] sm:w-[60%] bg-[#8569DA] hover:bg-[#FFFFFF] hover:border-[.4rem] hover:border-[#8569DA] h-[6.5rem] text-[2.4rem] text-[#FFFFFF] hover:text-[#8569DA] font-[300] rounded-[6rem] mt-[3.7rem]'
                    onClick={() => {
                        let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

                        if (!name) {
                            setIsValidName(false)
                        } else {
                            setIsValidName(true)
                        }
                        if (!email) {
                            setIsValidEmail(false)
                        } else {
                            setIsValidEmail(true)
                        }
                        if (!mobile) {
                            setIsValidMobile(false)
                        } else {
                            setIsValidMobile(true)
                        }
                        if (!name) {
                            console.log("Enter a valid name")
                            toast.error("Enter a valid name")
                            return;
                        }
                        if (!email) {
                            console.log("Enter a valid email")
                            toast.error("Enter a valid email")
                            return;
                        }
                        if (!mobile) {
                            console.log("Enter a valid mobile number")
                            toast.error("Enter a valid mobile number")
                            return;
                        }
                        if (!emailReg.test(email)) {
                            toast.error("Enter a valid email")
                            return;
                        }
                        if (mobile.length < 10) {
                            console.log("Enter a valid mobile number")
                            toast.error("Enter a valid mobile number")
                            return;
                        }
                        handleContactUs({
                            "fullName": name,
                            "email": email,
                            "contact": mobile,
                            "subjectLine": "Manufacturing and Supply Chain",
                            "message": message
                        })
                        setName('')
                        setEmail('')
                        setMobile('')
                        setMessage('')
                    }}
                >
                    Submit
                </button>
            </div>
            <div className='hidden lg:flex w-[50%] h-full' onClick={() => window.open("https://www.google.com/maps/place/Infominez+Pvt.+Ltd/@22.753493,75.9141254,21z/data=!4m14!1m7!3m6!1s0x3962fd15ba593323:0x40eb8cafe35828e3!2sInAdz+Digital!8m2!3d22.7535102!4d75.9141312!16s%2Fg%2F11b6b2g21m!3m5!1s0x39631d1355080bbd:0x1fb655ed1eb8f22e!8m2!3d22.7535531!4d75.914071!16s%2Fg%2F11vbyqyn92?entry=ttu")}>
                <img
                    className='w-full h-full'
                    src={ContactUsImgs.ContactUsMap}
                />
            </div>
        </div>
    )
}

export default DropUsALine
