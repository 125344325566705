import React from 'react'
import { BsCheckCircleFill } from "react-icons/bs";

function AdvantageWithUs({ advantagesWithUsSubHeading, advantagesWithUs, isAdvantageWithUsAlt, AdvantageWithUsAltBg }) {
    return isAdvantageWithUsAlt ? (
        <div id="advantage-with-us" className='advantage-with-us-alt w-full h-full relative'> 
            <div className='page-landing-sections-bg top-0 bottom-0 left-0 right-0 h-[135rem] lg:h-[110rem] [@media(min-width:1320px)]:h-full'>
                <img 
                    className='w-full h-full'
                    src={AdvantageWithUsAltBg}
                    alt='bg-img'
                />
            </div>
            <div className='position-absolute-center container-lg flex flex-col justify-center items-start my-[3rem] absolute'>
                <h1 className='text-[5rem] text-[#FFFFFF] font-[700] mb-[1rem]'>
                    Advantage with Us
                </h1>
                <h1 className='text-7xl text-[#FFFFFF] font-[400] mb-[4.5rem]'>
                    {advantagesWithUsSubHeading}
                </h1>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[4rem] md:gap-x-[10rem] gap-y-[2rem] md:gap-y-[4rem] bg-[#241F66]/[.82] py-[6.8rem] px-[5.7rem] rounded-[1.2rem]'>
                    {
                        advantagesWithUs?.map((item, i) => {
                            return (
                                <div className='flex justify-start items-start'>
                                    <BsCheckCircleFill className='mr-[1rem] my-[1rem]' size={'2rem'} color='#FFFFFF'/>
                                    <div className='flex flex-col justify-start items-start'>
                                        <h5 className='text-[2.4rem] font-[600] text-[#FFFFFF]'>
                                            {item.title} 
                                        </h5>
                                        <h5 className='text-xl font-[400] text-[#FFFFFF]'>
                                            {item.text}
                                        </h5>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    ) : (
        <div id="advantage-with-us" className='advantage-with-us container-lg flex flex-col justify-center items-start my-[4rem]'>
            <h1 className='text-[5rem] text-[#5C33A5] font-[700] mb-[1rem]'>
                Advantage with Us
            </h1>
            <h1 className='text-7xl text-[#585F69] font-[400] mb-[4.5rem]'>
                {advantagesWithUsSubHeading}
            </h1>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[4rem] md:gap-x-[10rem] gap-y-[2rem] md:gap-y-[4rem]'>
                {
                    advantagesWithUs?.map((item, i) => {
                        return (
                            <div className='flex justify-start items-start'>
                                <div className='advantage-with-us w-[10.6rem] h-[14.5rem] mr-[1.6rem] rounded-[1rem] shadow-[0_.4rem_1.4rem_0_rgba(0,0,0,0.25)]'>
                                    <img 
                                        className='w-full h-full rounded-[1rem]'
                                        src={item.icon}
                                    />
                                </div>
                                <div className='flex flex-col justify-start items-start w-[86%]'>
                                    <h5 className='text-[2.5rem] font-[600] text-[#29134F]'>
                                        {item.title} 
                                    </h5>
                                    <h5 className='text-2xl font-[400] text-[#95999F]'>
                                        {item.text}
                                    </h5>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AdvantageWithUs
