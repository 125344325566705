import React from 'react'
import { useParams } from 'react-router-dom';

function ServicesMiniNavbar({isSelected}) {
    const activePage = window.location.href.split("#")[window.location.href.split("#").length -1];

    return (
        <div className='services-mini-navbar w-full h-[9.3rem] bg-[#7445C9] mt-[5.9rem] sticky top-[10rem] z-40'>
            <div className='container-lg flex justify-start items-center h-full text-Montserrat-sans-serif'>
                <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "services-over-view" ? "font-[600]" : "font-[300]"}`} href='#services-over-view'>
                    Overview
                </a>
                {/* <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "" ? "font-[600]" : "font-[300]"}`} href='/#'>
                    Services
                </a> */}
                <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "advantage-with-us" ? "font-[600]" : "font-[300]"}`} href={`#advantage-with-us`}>
                    Advantage With US
                </a>
                <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "services-details-offerings" ? "font-[600]" : "font-[300]"}`} href={`#services-details-offerings`}>
                    Our Services
                </a>
                {/* <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "" ? "font-[600]" : "font-[300]"}`} href='/#'>
                    Case Study
                </a> */}
                {/* <a className={`nav-link mr-[4rem] tracking-wider text-base text-[#FFFFFF] ${activePage === "" ? "font-[600]" : "font-[300]"}`} href='/#'>
                    Latest Insights
                </a> */}

            </div>
        </div>
    )
}

export default ServicesMiniNavbar
