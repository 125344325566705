import React from 'react'
import { BsCheckCircleFill } from "react-icons/bs";

function ProcessModelSecCard({ id, isFocused, title, bgImg, setSelectId }) {
    return (
        <div 
            className='process-model-sec-card cursor-pointer relative w-full h-full'
            onMouseEnter={() => setSelectId(id)}
            onMouseLeave={() => setSelectId(null)}
        >
            <div className={`process-model-sec-card-bg top-0 bottom-0 left-0 right-0`}>
                <img 
                    className='w-full h-full'
                    src={bgImg}
                />
            </div>
            <div className={``}>
                <div className={`process-model-sec-card-content position-absolute-center flex flex-col justify-center items-center absolute w-full h-full ${isFocused ? "focused-pmscb" : "bg-[#051C43]/[.69]"}`}>
                    <BsCheckCircleFill size={'3.7rem'} color='#FFFFFF'/>
                    <pre className='text-2xl text-[#FFFFFF] text-center leading-10 mt-4'>
                        {title}
                    </pre>
                </div>
            </div>
        </div>
    )
}

export default ProcessModelSecCard
