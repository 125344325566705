import React from 'react'
import '../../../static/styles/common/HomeLandingSections/HomeLandingSections.css'
import { LandingImgs } from '../../../static/constants/imgConstants/imgConstants'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useNavigate } from 'react-router-dom';

const handleDragStart = (e) => e.preventDefault();

function HomeLandingSections({ heading, subHeading, actionTitle }) {

    const navigate = useNavigate(); 

    const items = [
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <h1 className='home-landing-sections-heading text-11xl lg:text-16xl text-[#FFFFFF] text-center mb-[2rem]'>
                <pre className='text-Montserrat-sans-serif font-[300] tracking-tighter leading-[6rem] md:leading-[10rem]'>
                    {heading ? heading : 'heading'}
                </pre>
            </h1>
            <h1 className='home-landing-sections-heading text-[1.8rem] md:text-xl md:tracking-wider text-[#FFFFFF] text-center mb-[2rem] my-[4rem]'>
                <pre>
                    {subHeading ? subHeading : 'subHeading'}
                </pre>
            </h1>
            <div 
                className='flex justify-center items-center text-6xl lg:text-8xl cursor-pointer text-[#FFFFFF] text-center my-[2rem]'
                onClick={() => {navigate("/contact-us")}}
            >
                <div className='home-landing-sections-action-icon w-[4rem] h-[2.8rem] lg:w-[6.2rem] lg:h-[5rem] mr-[2rem]'>
                    <img className='w-full h-full' src={LandingImgs.HomeLandingActionIcon} alt='icon'/>
                </div>
                {actionTitle ? actionTitle : 'actionTitle'}
            </div>
        </div>,
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <h1 className='home-landing-sections-heading text-11xl lg:text-16xl text-[#FFFFFF] text-center mb-[2rem]'>
                <pre className='text-Montserrat-sans-serif font-[300] tracking-tighter leading-[6rem] md:leading-[10rem]'>
                    {`Product Engineering\nServices`}
                </pre>
            </h1>
            <h1 className='home-landing-sections-heading text-[1.8rem] md:text-xl md:tracking-wider text-[#FFFFFF] text-center mb-[2rem] my-[4rem]'>
                <pre>
                    {`Innovate. Engineer. Excel.\nCrafting Tomorrow's Technology: Expert Product Engineering\nSolutions Tailored for Your Success"`}
                </pre>
            </h1>
            <div 
                className='flex justify-center items-center text-6xl lg:text-8xl cursor-pointer text-[#FFFFFF] text-center my-[2rem]'
                onClick={() => {navigate("/contact-us")}}
            >
                <div className='home-landing-sections-action-icon w-[4rem] h-[2.8rem] lg:w-[6.2rem] lg:h-[5rem] mr-[2rem]'>
                    <img className='w-full h-full' src={LandingImgs.HomeLandingActionIcon} alt='icon'/>
                </div>
                {actionTitle ? actionTitle : 'actionTitle'}
            </div>
        </div>,
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <h1 className='home-landing-sections-heading text-11xl lg:text-16xl text-[#FFFFFF] text-center mb-[2rem]'>
                <pre className='text-Montserrat-sans-serif font-[300] tracking-tighter leading-[6rem] md:leading-[10rem]'>
                    {`Blockchain Technology\nServices`}
                </pre>
            </h1>
            <h1 className='home-landing-sections-heading text-[1.8rem] md:text-xl md:tracking-wider text-[#FFFFFF] text-center mb-[2rem] my-[4rem]'>
                <pre>
                    {`Revolutionizing Tomorrow: Our Blockchain Solutions`}
                </pre>
            </h1>
            <div 
                className='flex justify-center items-center text-6xl lg:text-8xl cursor-pointer text-[#FFFFFF] text-center my-[2rem]'
                onClick={() => {navigate("/contact-us")}}
            >
                <div className='home-landing-sections-action-icon w-[4rem] h-[2.8rem] lg:w-[6.2rem] lg:h-[5rem] mr-[2rem]'>
                    <img className='w-full h-full' src={LandingImgs.HomeLandingActionIcon} alt='icon'/>
                </div>
                {actionTitle ? actionTitle : 'actionTitle'}
            </div>
        </div>,
    ];

    return (
        <div className='home-landing-sections relative w-full h-[78rem] overflow-hidden'>
            <div className='home-landing-sections-bg top-0 bottom-0 left-0 right-0 mt-[10rem] h-full'>
                <img 
                    className='w-full h-full'
                    src={LandingImgs.HomeLandingImg}
                    alt='bg-img'
                />
            </div>
            <div className='home-landing-sections-content container-lg absolute'>
                <AliceCarousel autoPlayInterval={8000} infinite autoPlayStrategy='default' autoPlay mouseTracking items={items}/>
            </div>
        </div>
    )
}

export default HomeLandingSections
