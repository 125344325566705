import React from 'react'
import { ContactIconImgs, LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router';

function ServicesCard({ icon, title, content, isServicesAlt, path  }) {
    const navigate = useNavigate(); 

    return (
        <div className={`services-card cursor-pointer h-[40rem] xs:h-[50rem] xs:z-20 flex flex-col justify-center items-center px-[3.2rem] py-[2rem] hover:shadow-[0_3.4rem_8.4rem_0_rgba(63,42,131,0.15)]`} onClick={()=>{navigate(path)}}> 
            <div className='services-card w-[6.8rem] h-[7rem] my-[2rem]'>
                <img 
                    className='w-full h-full'
                    src={icon}
                />
            </div>
            <pre className='text-2xl sm:text-3xl font-[500] sm:font-[600] text-[#3C436A] text-Montserrat-sans-serif text-center my-[2rem]'>
                {title}
            </pre>
            <h2 className='text-lg font-[400] sm:font-[500] text-[#707F96] text-center my-[2rem] text-DM-Sans-sans-serif'>
                {content}
            </h2>
            {!isServicesAlt &&
                <div className='flex justify-center items-center my-[1rem] text-sm text-[#094CF8]'>
                    <div className='w-[2.5rem] h-[2.1rem] mr-[1.2rem]'>
                        <img 
                            className='w-full h-full'
                            src={LatestInsightsImgs.ActionIconBlueImg}
                        />
                    </div>
                    <div className="" onClick={()=>{navigate(path)}}>
                        Learn More
                    </div>
                </div>
            }
        </div>
    )
}

export default ServicesCard

// box-shadow: 0px 34px 84px 0px rgba(63, 42, 131, 0.15);
// 1x

// drop-shadow-[0_3.4rem_8.4rem_0_rgba(63,42,131,0.15)]
