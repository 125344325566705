import React, { useState } from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import ServicesCard from '../../common/Cards/ServicesCard'
import { EndToEndDevelopmentServicesImgs, ServicesPageImgs } from '../../../static/constants/imgConstants/imgConstants';

function ServicesSec({ isServicesAlt, subHeading }) {
    const [data] = useState([
        {   
            id: 1,
            title: `Product Engineering\nServices`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesImg1,
            path: "/services/product-engineering",
            content: "Our Product Engineering Services offer end-to-end solutions to transform your ideas into  successful products.",
        },
        {   
            id: 2,
            title: `Data Governance &\nAnalytics Services`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesImg2,
            path: "/services/data-governance-and-analytics",
            content: "Our Data Governance & Analytics Services empower businesses to harness the power of data  for informed decision-making.",
        },
        {   
            id: 3,
            title: `Blockchain Technology\nServices`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesImg3,
            path: "/services/blockchain",
            content: "Our Blockchain Technology Services provide cutting-edge solutions to harness the power of  blockchain technology for your business.",
        },
        {   
            id: 4,
            title: `Cloud Engineering\n  `,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesImg4,
            path: "/services/cloud-engineering",
            content: "Our Cloud Engineering Services help you in achieving transformational change on a broad scale and at a rapid pace.",
        },
    ]);

    return (
        <div className='services py-[10rem] flex flex-col justify-center items-center relative'>
            {!isServicesAlt && 
                <SectionHeaders
                    contentPrimary="Service "
                    contentSecondary="Offering"
                    classes="text-6xl font-[700] mb-[1.2rem]"
                />
            }
            <h2 className='text-[4.5rem] text-[#3C436A] font-[300] text-center'>
                {subHeading}
            </h2>
            <p className='text-3xl text-[#4E617C] font-[400] text-center my-[2rem] container-lg'>
                {`Infominez stands as a dynamic service offering, dedicated to empowering businesses by providing them with a\ndistinct competitive advantage through the strategic application of state-of-the-art technology solutions.`}
            </p>
            <div className='container-lg grid  grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 px-[4rem] lg:px-0'>
                {
                    data.map((item) => {
                        return (
                            <ServicesCard
                                title={item.title}
                                content={item.content}
                                icon={item.icon}
                                isServicesAlt={isServicesAlt}
                                path={item.path}
                            />
                        )
                    })
                }
                <div className="hidden sm:flex absolute w-[14.5rem] h-[14.5rem] right-0 bottom-[10rem]">
                    <img src={ServicesPageImgs.rightSemiCircleSmall} alt="" className="" />
                </div>
            </div>
        </div>
    )
}

export default ServicesSec
