import React from 'react'
import { SocialIconImgs, TestimonyCardImgs } from '../../../static/constants/imgConstants/imgConstants'

function TestimonyCard({ message, name, role, linkedinLink, twitterLink, image, imageWidth, handleDragStart }) {
    return (
        <div handleDragStart={handleDragStart} className='w-full flex flex-col md:flex-row justify-between items-center md:items-end mt-[4rem]'>
            <div className={imageWidth ? imageWidth : "w-[100%] md:w-[30%]"}>
                <img 
                    className='w-full h-full'
                    src={image}
                />
            </div>
            <div className='testimony-card w-[100%] md:w-[56%] h-full'>
                <div className='testimony-card w-full h-full'>
                    <div className='flex flex-col justify-between items-start'>
                        <div className='quotation-img w-[8.6rem] h-[6.6rem] mb-[1.2rem] md:mb-[3rem]'>
                            <img 
                                className='w-full h-full'
                                src={TestimonyCardImgs.QuotationImg1}
                            />
                        </div>
                        <p className='text-[2.3rem] text-[#FFFFFF]'>
                            {message}
                        </p>
                        <div className='flex justify-between items-center w-full'>
                            <div className='flex flex-col justify-between items-start'>
                                <h1 className='text-[3rem] text-[#FFFFFF]'>
                                    {name}
                                </h1>
                                <h1 className='text-[1.3rem] text-[#FFFFFF] mt-[1rem] mb-[1.2rem] md:mb-[4.4rem]'>
                                    {role}
                                </h1>
                                <div className='flex justify-between items-center'>
                                    <div 
                                        className='flex cursor-pointer text-left text-[1.3rem] text-[#FFFFFF] mr-[2rem]'
                                        onClick={() => {window.open(linkedinLink, '_blank');}}
                                    >
                                        <div className='social-icons w-[2.1rem] h-[2rem] mr-[.8rem]'>
                                            <img 
                                                className='w-full h-full'
                                                src={SocialIconImgs.LinkedinIconWhiteImg}
                                            />
                                        </div>
                                        Linkedin
                                    </div>
                                    <div 
                                        className='flex cursor-pointer text-left text-[1.3rem] text-[#FFFFFF]'
                                        onClick={() => {window.open(twitterLink, '_blank');}}
                                    >
                                        <div className='social-icons w-[2.1rem] h-[2rem] mr-[.8rem]'>
                                            <img 
                                                className='w-full h-full'
                                                src={SocialIconImgs.TwitterIconWhiteImg}
                                            />
                                        </div>
                                        Twitter
                                    </div>
                                </div>
                            </div>
                            <div className='quotation-img w-[28rem] md:w-[34rem] h-[20rem] md:h-[28rem] opacity-25'>
                                <img 
                                    className='w-full h-full'
                                    src={TestimonyCardImgs.QuotationImg2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonyCard
