import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom'
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import PageLandingSection from '../../components/common/LandingSections/PageLandingSection';
import PageInfoIndustries from '../../components/common/PageInfo/PageInfoIndustries';
import IndustriesDetailsServiceOfferings from '../../components/main/IndustriesDetailsServiceOfferings/IndustriesDetailsServiceOfferings';
import AdvantageWithUs from '../../components/main/AdvantageWithUs/AdvantageWithUs';
import IndustriesDetailsBanner from '../../components/common/IndustriesDetailsBanner/IndustriesDetailsBanner';
import IndustriesDetailsTechStack from '../../components/main/IndustriesDetailsTechStack/IndustriesDetailsTechStack';
import { AdvantageWithUsImgs, IndustriesDetailsServiceOfferingsImgs, IndustriesDetailsTechIconSecImgs, LandingImgs, PageInfoIndustriesImgs } from '../../static/constants/imgConstants/imgConstants';

function IndustriesDetails() {

    const { industriesType } = useParams();
    const [industriesDetailedData, setIndustriesDetailedData] = useState([
        {
            "id": 1,      
            "type": 'fintech',
            "title": "Fintech",
            "landingImg": PageInfoIndustriesImgs.PageInfoIndustriesImg1,
            "subTitle": "Empowering the banking sector with advanced technology solutions, enhancing customer experiences and optimizing financial operations.",
            "pageInfo" : [
                "With a deep-rooted understanding of the financial industry, we bring cutting-edge technology and innovation to empower businesses in the financial sector.",
                "We have integration capabilities in Card Payment, Digital Gold/ Silver – Buy / Sell, AEPS- Aadhar Enabled Payment System, IMPS-Immediate Payment System, DMT- Direct Money Transfer, PPI – Pre-paid Instrument, Prepaid & Gift cards System",
                "Our journey in Fintech began with a vision to revolutionize the industry by providing tailor-made solutions that drive growth and efficiency.",
            ],
            "pageInfoImg": PageInfoIndustriesImgs.PageInfoIndustriesImg,
            "serviceOfferingsImg": IndustriesDetailsServiceOfferingsImgs.IndustriesDetailsServiceOfferingsImg1,
            "serviceOfferings": [
                {
                    "id": 1,
                    "title": "Fintech Software Development: ",
                    "text": "we specialize in crafting bespoke software solutions for Fintech companies. Whether it's mobile apps, web platforms, or advanced analytics, we have you covered.",
                },
                {
                    "id": 2,
                    "title": "Payment Processing Solutions: ",
                    "text": "Our payment processing solutions ensure secure and seamless transactions, enabling your business to thrive in the digital payment ecosystem.",
                },
                {
                    "id": 3,
                    "title": "Blockchain and Cryptocurrency: ",
                    "text": "Harness the power of blockchain and cryptocurrencies with our expert guidance and development services. Stay ahead in this transformative space.",
                },
                {
                    "id": 4,
                    "title": "Regulatory Compliance: ",
                    "text": "We offer comprehensive regulatory compliance solutions, ensuring your Fintech products meet all legal requirements and industry standards.",
                },
                {
                    "id": 5,
                    "title": "Data Analytics: ",
                    "text": "Leverage data-driven insights to make informed decisions. Our data analytics services help you extract valuable insights from your financial data.",
                },
                {
                    "id": 6,
                    "title": "Security & Fraud prevention: ",
                    "text": "Added cooling periods, Multiple authentications, PPI limits monitoring.",
                },
            ],
            "advantagesWithUsSubHeading": "At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should choose us:",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Industry Expertise",
                    "text": "Our team comprises Fintech experts who understand the nuances of the financial sector."
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Innovation",
                    "text": "We stay at the forefront of technology trends, ensuring you always have a competitive edge."
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Custom Solutions",
                    "text": "Tailored solutions that fit your unique business needs."
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Security",
                    "text": "Robust security measures to protect your financial data."
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Client-Centric Approach",
                    "text": "Your success is our priority, and we work closely with you to achieve it."
                },
            ],
            "techStackSubTitle": `At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should\nchoose us:`, 
            "techStackData": [
                {
                    "id": 1,
                    "title": "Programming Languages",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg1, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg2, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg3
                    ],
                },
                {
                    "id": 2,
                    "title": "Frameworks",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg4, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg5, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg6
                    ],
                },
                {
                    "id": 3,
                    "title": "Cloud Services",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg7, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg8, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg9
                    ],
                },
                {
                    "id": 4,
                    "title": "Data Analytics",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg10, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg11, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg12
                    ],
                },
                {
                    "id": 5,
                    "title": "Security",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg13, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg14, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg15
                    ],
                },
                {
                    "id": 6,
                    "title": "Blockchain",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg16, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg17, 
                    ],
                },
            ],
            metaTitle: 'FinTech Solutions | Financial Technology Services | Infominez',
            metaDescription: 'Infominez excels in delivering innovative Fintech solutions. From Card Payment integration to Digital Gold trading and Direct Money Transfer, we empower the financial sector with cutting-edge technology and tailor-made services.',
            metaKeyword: 'Fintech solutions, financial technology, Card Payment, Digital Gold, AEPS, IMPS, DMT, PPI, Prepaid & Gift cards, financial innovation',
            metaURL: 'https://infominez.com/industries/fintech',
        },
        {
            "id": 2,      
            "type": 'banking',
            "title": "Banking",
            "landingImg":PageInfoIndustriesImgs.PageInfoIndustriesImg2,
            "subTitle": "Empowering the banking sector with advanced technology solutions, enhancing customer experiences and optimizing financial operations.",
            "pageInfo" : [
                "We are your strategic partner in revolutionizing the banking industry through innovative technology solutions. ",
                "With rich experience in the financial sector such as Bank Switch feed handling, ATM Incident Management System - Cash operation automation, Ticket Management, Reconciliation,, Advance predictive reports - Al driven ATM usage report, predictive ATM health analysis",
                "We are committed to delivering cutting-edge software and digital services to help banks thrive in an ever-evolving landscape.",
            ],
            "pageInfoImg": PageInfoIndustriesImgs.PageInfoIndustriesImg,
            "serviceOfferingsImg": IndustriesDetailsServiceOfferingsImgs.IndustriesDetailsServiceOfferingsImg2,
            "serviceOfferings": [
                {
                    "id": 1,
                    "title": "Digital Banking Solutions: ",
                    "text": "Elevate your bank's digital presence with our state-of-the-art digital banking solutions, including online and mobile banking platforms.",
                },
                {
                    "id": 2,
                    "title": "Core Banking Systems: ",
                    "text": "Streamline your operations and enhance customer experiences with our robust core banking system solutions.",
                },
                {
                    "id": 3,
                    "title": "Payment Processing: ",
                    "text": "Ensure seamless and secure payment processing with our payment gateway and transaction solutions.",
                },
                {
                    "id": 4,
                    "title": "Risk Management: ",
                    "text": "Mitigate risks effectively with our comprehensive risk management tools and expertise.",
                },
                {
                    "id": 5,
                    "title": "Regulatory Compliance: ",
                    "text": "Stay ahead of regulatory changes with our compliance solutions tailored to the banking industry.",
                },
            ],
            "advantagesWithUsSubHeading": "Choose Infominez as your banking technology partner and enjoy the following advantages:",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Industry Insights",
                    "text": "Our team possesses deep knowledge of the banking sector, ensuring you receive tailored solutions."
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Innovation",
                    "text": "We embrace emerging technologies to future-proof your banking operations."
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Scalable Solutions",
                    "text": "Our offerings are designed to grow with your bank's needs."
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Security",
                    "text": "Protect your customers' data with our robust security measures."
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Client-Centric Approach",
                    "text": "Your satisfaction is our priority, and we work closely with you to achieve your goals."
                },
            ],
            "techStackSubTitle": `At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should\nchoose us:`, 
            "techStackData": [
                {
                    "id": 1,
                    "title": "Programming Languages",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg1, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg2, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg18
                    ],
                },
                {
                    "id": 2,
                    "title": "Frameworks",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg19, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg6,
                    ],
                },
                {
                    "id": 3,
                    "title": "Cloud Services",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg7, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg8, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg9
                    ],
                },
                {
                    "id": 4,
                    "title": "Database Management",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg20, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg21, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg22
                    ],
                },
                {
                    "id": 5,
                    "title": "Security",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg13, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg14, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg15
                    ],
                },
                {
                    "id": 6,
                    "title": "Blockchain",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg23, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg24, 
                    ],
                },
            ],
            metaTitle: 'Banking IT Solutions | Secure Banking Technology | Infominez',
            metaDescription: 'Partner with Infominez to revolutionize the banking industry. Our innovative technology solutions, including ATM management and predictive analytics, help banks thrive in the digital age.',
            metaKeyword: 'banking solutions, technology in banking, ATM management, predictive analytics, digital banking, financial technology',
            metaURL: 'https://infominez.com/industries/banking',
        },
        {
            "id": 3,      
            "type": 'supply-chain-and-retail-management',
            "title": "Supply Chain & Retail Management" ,
            "landingImg":PageInfoIndustriesImgs.PageInfoIndustriesImg3,
            "subTitle": "Optimizing supply chain and retail management with innovative technology solutions for enhanced efficiency and customer satisfaction",
            "pageInfo" : [
                "We can be your strategic partner in transforming supply chain and retail management through cutting-edge technology solutions.",
                "With a deep understanding of the logistics and retail industry, we are committed to delivering innovative software and digital services to help businesses optimize their operations and meet the demands of a rapidly changing market.",
            ],
            "pageInfoImg": PageInfoIndustriesImgs.PageInfoIndustriesImg,
            "serviceOfferingsImg": IndustriesDetailsServiceOfferingsImgs.IndustriesDetailsServiceOfferingsImg3,
            "serviceOfferings": [
                {
                    "id": 1,
                    "title": "Supply Chain Optimization: ",
                    "text": "Enhance your supply chain efficiency and visibility with our advanced supply chain optimization solutions, including demand forecasting, inventory management, and logistics optimization.",
                },
                {
                    "id": 2,
                    "title": " Retail Management Solutions: ",
                    "text": "Elevate your retail operations with our comprehensive retail management systems, offering point-of-sale (POS) solutions, inventory tracking, and customer relationship management (CRM) tools.",
                },
                {
                    "id": 3,
                    "title": "E-commerce Integration: ",
                    "text": "Seamlessly integrate your retail business into the digital realm with our e-commerce solutions, ensuring a unified and efficient online and offline presence.",
                },
                {
                    "id": 4,
                    "title": "Warehouse Management: ",
                    "text": "Streamline your warehouse operations with our warehouse management systems (WMS) and improve inventory accuracy and order fulfillment speed.",
                },
                {
                    "id": 5,
                    "title": "Data Analytics: ",
                    "text": "Harness the power of data with our analytics solutions, providing you with valuable insights for informed decision-making in both supply chain and retail management.",
                },
            ],
            "advantagesWithUsSubHeading": "At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should choose us:",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Industry Expertise",
                    "text": "Our team boasts extensive knowledge of the supply chain and retail sector, ensuring tailored solutions to address your specific needs."
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Innovation",
                    "text": "We leverage emerging technologies to future-proof your operations and help you stay competitive."
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Scalable Solutions",
                    "text": "Our offerings are designed to adapt and grow with your business."
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Security",
                    "text": "Your data and transactions are safeguarded by robust security measures."
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Client-Centric Approach",
                    "text": "Your success is our priority, and we collaborate closely with you to achieve it."
                },
            ],
            "techStackSubTitle": "At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should choose us:", 
            "techStackData": [
                {
                    "id": 1,
                    "title": "Programming Languages",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg1, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg2, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg25
                    ],
                },
                {
                    "id": 2,
                    "title": "Frameworks",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg26, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg27, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg6
                    ],
                },
                {
                    "id": 3,
                    "title": "Cloud Services",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg7, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg8, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg9
                    ],
                },
                {
                    "id": 4,
                    "title": "Database Management",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg28, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg29, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg30
                    ],
                },
                {
                    "id": 5,
                    "title": "Data Analytics",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg10, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg11, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg12
                    ],
                },
            ],
            metaTitle: 'Supply Chain & Retail Management IT Solutions | Infominez',
            metaDescription: 'Partner with Infominez for transformative supply chain and retail management solutions. Leverage our expertise in logistics and retail to optimize operations and thrive in a dynamic market.',
            metaKeyword: 'Supply Chain Solutions, Retail Management, Logistics Optimization, Digital Services, Technology Solutions, Industry Expertise',
            metaURL: 'https://infominez.com/industries/supply-chain-and-retail-management',
        },
        {
            "id": 4,      
            "type": 'edutech',
            "title": "EduTech",
            "landingImg":PageInfoIndustriesImgs.PageInfoIndustriesImg4,
            "subTitle": "Revolutionizing education with cutting-edge Edutech solutions, fostering interactive learning and academic excellence",
            "pageInfo" : [
                "We can be your trusted partner in the Edu-Tech sector. We are dedicated to transforming education through innovative technological solutions.",
                "With a profound understanding of the educational landscape, we are committed to providing cutting-edge software and digital services that empower educators and learners alike.",
            ],
            "pageInfoImg": PageInfoIndustriesImgs.PageInfoIndustriesImg,
            "serviceOfferingsImg": IndustriesDetailsServiceOfferingsImgs.IndustriesDetailsServiceOfferingsImg4,
            "serviceOfferings": [
                {
                    "id": 1,
                    "title": "Learning Management Systems (LMS): ",
                    "text": "Revolutionize your educational institution with our state-of-the-art LMS solutions, offering course management, content delivery, and student assessment tools.",
                },
                {
                    "id": 2,
                    "title": "E-Learning Platforms: ",
                    "text": "Create engaging and interactive online learning experiences with our customizable e-learning platforms, catering to various educational needs.",
                },
                {
                    "id": 3,
                    "title": "Virtual Classroom Solutions:",
                    "text": "Facilitate remote learning with our virtual classroom solutions, featuring live video streaming, interactive whiteboards, and real-time collaboration.",
                },
                {
                    "id": 4,
                    "title": "Edutainment Apps: ",
                    "text": "Engage young learners with our educational entertainment apps, combining learning and play for an enjoyable educational experience.",
                },
                {
                    "id": 5,
                    "title": "Data Analytics: ",
                    "text": "Harness the power of data-driven insights with our analytics solutions, providing educators with valuable information to enhance teaching and learning outcomes.",
                },
            ],
            "advantagesWithUsSubHeading": "Choose Infominez as your banking technology partner and enjoy the following advantages:",
            "advantagesWithUs": [
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Education Expertise",
                    "text": "Our team consists of educators and technologists with a deep understanding of educational needs and challenges."
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Innovation",
                    "text": "We leverage emerging technologies to future-proof your operations and help you stay competitive."
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Scalable Solutions",
                    "text": "Our offerings are designed to adapt and grow with your business."
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Security",
                    "text": "Your data and transactions are safeguarded by robust security measures."
                },
                {
                    "id": 6,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Client-Centric Approach",
                    "text": "Your success is our priority, and we collaborate closely with you to achieve it."
                },
            ],
            "techStackSubTitle": "At Infominez, we pride ourselves on being the Fintech partner of choice. Here's why you should choose us:", 
            "techStackData": [
                {
                    "id": 1,
                    "title": "Programming Languages",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg1, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg2, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg25
                    ],
                },
                {
                    "id": 2,
                    "title": "Frameworks",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg26, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg27, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg6
                    ],
                },
                {
                    "id": 3,
                    "title": "Cloud Services",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg7, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg8, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg9
                    ],
                },
                {
                    "id": 4,
                    "title": "Database Management",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg28, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg29, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg30
                    ],
                },
                {
                    "id": 5,
                    "title": "Data Analytics",
                    "icons": [
                        IndustriesDetailsTechIconSecImgs.TechIconImg10, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg11, 
                        IndustriesDetailsTechIconSecImgs.TechIconImg12
                    ],
                },
            ],
            metaTitle: 'EduTech Solutions | Innovative Education Technology | Infominez',
            metaDescription: "Revolutionize education with Infominez's EduTech solutions. Embrace our technological expertise to empower educators and learners with innovative software and digital services.",
            metaKeyword: 'EduTech, Educational Technology, Innovative Solutions, Software for Education, Digital Services, Empowering Educators, Learner-Centric Solutions',
            metaURL: 'https://infominez.com/industries/edutech',
        },
    ])

    return (
        <div className='fintech-industries'>
            <PrimaryLayout
                showNavbar={true}
                // ShowCaseStudy={true}
                // showLatestInsights={true}
                showContactUs={true}
                showFooterMain={true}
                ShowFooterMini={true}
            >
                {industriesDetailedData?.filter((industry) => { return industry.type === industriesType })?.length > 0 ?
                    industriesDetailedData?.filter((industry) => { return industry.type === industriesType })?.map((industry, i) => {
                        return (
                            <>
                                <Helmet>
                                    <title>{industry.metaTitle}</title>
                                    <meta name="description" content={industry.metaDescription} />
                                    <meta name="keywords" content={industry.metaDescription} />
                                    <meta name="author" content="Infominez Dev Team" />
                                    <meta property="og:title" content={industry.metaTitle} />
                                    <meta property="og:description" content={industry.metaDescription} />
                                    <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                                    <meta property="og:url" content={industry.metaURL} />
                                    <meta name="twitter:title" content={industry.metaTitle} />
                                    <meta name="twitter:description" content={industry.metaDescription} />
                                    <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                                    <meta name="twitter:card" content="summary_large_image" />
                                </Helmet>
                                <PageLandingSection
                                    isDetailsPage
                                    heading={industry.title}
                                    subHeading={industry.subTitle}
                                    LandingSecImgs={industry.landingImg}
                                />
                                <PageInfoIndustries 
                                    pageInfo={industry.pageInfo} 
                                    pageInfoImg={industry.pageInfoImg}
                                />
                                <IndustriesDetailsServiceOfferings 
                                    serviceOfferings={industry.serviceOfferings}
                                    serviceOfferingsImg={industry.serviceOfferingsImg}
                                />
                                <AdvantageWithUs 
                                    advantagesWithUsSubHeading={industry.advantagesWithUsSubHeading}
                                    advantagesWithUs={industry.advantagesWithUs}
                                />
                                <IndustriesDetailsBanner />
                                <IndustriesDetailsTechStack 
                                    techStackSubTitle={industry.techStackSubTitle} 
                                    techStackData={industry.techStackData}
                                />
                            </>
                        )
                    })
                    :
                    null
                }
            </PrimaryLayout>
        </div>
    )
}

export default IndustriesDetails
