import React, { useEffect, useState } from 'react';
import { NavbarImgs } from '../../../static/constants/imgConstants/imgConstants';
import { useNavigate } from 'react-router-dom';
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';

function Navbar() {

    const navigate = useNavigate(); 
    const activePage = window.location.href.split("/")[window.location.href.split("/").length -1];

    const [isDetailsPage, setIsDetailsPage] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useEffect(() => {
        // if(activePage === "#about-us"){
        //     window.scrollBy(0, 900);
        //     return;
        // }
        if(
            activePage === "product-engineering" ||
            activePage === "data-governance-and-analytics" ||
            activePage === "blockchain" ||
            activePage === "cloud-engineering" ||
            activePage === "fintech" ||
            activePage === "banking" ||
            activePage === "supply-chain-and-retail-management" ||
            activePage === "edutech" 
        ){
            setIsDetailsPage(true)
        }else {
            setIsDetailsPage(false)
        }
        window.scrollTo(0,10);
    }, [activePage]);

    const navbar = document.getElementById('navbar');
    let scrolled = false;
    window.onscroll = function () {
        if (activePage !== "#about-us" && window.pageYOffset > 10) {
            navbar.classList.add('navbar-bg');

            if (!scrolled) {
                navbar.style.transform = 'translateY(-10rem)';
            }
            setTimeout(function () {
                navbar.style.transform = 'translateY(0)';
                scrolled = true;
            }, 200)
        }
        else {
            scrolled = false;
            navbar.classList.remove('navbar-bg');
        }
        if(activePage == "#about-us") {
            navbar.classList.add('navbar-bg');
        }
        if(activePage == "#") {
            navbar.classList.add('navbar-bg');
        }
    }

    return (
        <nav id="navbar" className={`navbar ${!isDetailsPage && "navbar-bg"} transition ease-in duration-100 flex justify-center items-center w-full h-[10rem] fixed top-0 z-[80]`}>
            <div className='nav-container flex justify-between items-center container-lg py-[2rem]'>
                <div 
                    className='nav-logo cursor-pointer'
                    onClick={() => {navigate("/")}}
                >
                    <img 
                        className='w-full h-full'
                        src={NavbarImgs.InfominezLogoLight}
                        alt='logo'
                    />
                </div>
                <div className='flex justify-end items-center'>
                    <div className='hidden md:flex flex-col justify-between items-center mx-[1.6rem]'>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/#'>
                            Home
                        </a>
                        {
                            activePage === "" || 
                            activePage === "#about-us" || 
                            activePage === "#" ? 
                                <div className='w-[80%] h-[.3rem] rounded-[.3rem] bg-[#BD5FFF]'></div> 
                                : 
                                ""
                        }
                    </div>
                    <div className='hidden md:flex flex-col justify-between items-center mx-[1.6rem]'>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/services'>
                            Services
                        </a>
                        {
                            activePage === "services" || 
                            activePage === "product-engineering" || 
                            activePage === "data-governance-and-analytics" || 
                            activePage === "blockchain" || 
                            activePage === "cloud-engineering" ||
                            activePage === "product-engineering#advantage-with-us" || 
                            activePage === "data-governance-and-analytics#advantage-with-us" || 
                            activePage === "blockchain#advantage-with-us" || 
                            activePage === "cloud-engineering#advantage-with-usg" ||
                            activePage === "product-engineering#services-details-offerings" || 
                            activePage === "data-governance-and-analytics#services-details-offerings" || 
                            activePage === "blockchain#services-details-offerings" || 
                            activePage === "cloud-engineering#services-details-offerings" ||
                            activePage === "product-engineering#services-over-view" || 
                            activePage === "data-governance-and-analytics#services-over-view" || 
                            activePage === "blockchain#services-over-view" || 
                            activePage === "cloud-engineering#services-over-view" ? 
                                <div className='w-[80%] h-[.3rem] rounded-[.3rem] bg-[#BD5FFF]'></div> 
                                : 
                                ""
                        }
                    </div>
                    <div className='hidden md:flex flex-col justify-between items-center mx-[1.6rem]'>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/industries'>
                            Industries
                        </a>
                        {
                            activePage === "industries" || 
                            activePage === "fintech" || 
                            activePage === "banking" || 
                            activePage === "supply-chain-and-retail-management" || 
                            activePage === "edutech" ?
                                <div className='w-[80%] h-[.3rem] rounded-[.3rem] bg-[#BD5FFF]'></div> 
                                : 
                                ""
                        }
                    </div>
                    <div className='hidden lg:flex flex-col justify-between items-center mx-[1.6rem]'>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/contact-us'>
                            Contact Us
                        </a>
                        {
                            activePage === "contact-us" ? 
                                <div className='w-[80%] h-[.3rem] rounded-[.3rem] bg-[#BD5FFF]'></div> 
                                : 
                                ""
                        }
                    </div>
                    <BtnOutlined 
                        title="Get Started"
                        width="w-[15rem] ml-[2rem]"
                        textSize="text-base"
                        titleColor="text-[#FFFFFF] hover:text-[#FFFFFF]"
                        borderColor="border-[#79AFFF]"
                        bgColor={"bg-inherit hover:bg-[#79AFFF]"}
                        onClick={() => {navigate("/contact-us")}}
                    />
                    <div className='md:hidden flex flex-col justify-between items-center ml-[1.6rem] relative'>
                        <div className={`nav-icon ${isMenuOpen && "open"}`} onClick={() => {setIsMenuOpen(!isMenuOpen)}}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className={`absolute top-[6.5rem] w-[35rem] ${isMenuOpen ? "left-[-16rem] ease-in duration-300" : "left-[16rem] ease-out duration-300" }`}>
                            <div className='flex flex-col justify-start items-start py-[1.2rem] bg-[#12093D]'>
                                <div className='flex flex-row-reverse justify-between items-center py-[.7rem] mx-[1.6rem]'>
                                    <a className={`nav-link text-base ${activePage === "" || activePage === "#" ? "text-[#BD5FFF] scale-[105%] font-[600]" : "text-[#FFFFFF]"} mb-[.4rem]`} href='/#'>
                                        Home
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center py-[.7rem] mx-[1.6rem]'>
                                    <a className={`nav-link text-base ${activePage === "services" || activePage === "product-engineering" || activePage === "data-governance-and-analytics" || activePage === "blockchain" || activePage === "cloud-engineering" ||activePage === "product-engineering#advantage-with-us" || activePage === "data-governance-and-analytics#advantage-with-us" || activePage === "blockchain#advantage-with-us" || activePage === "cloud-engineering#advantage-with-usg" ||activePage === "product-engineering#services-details-offerings" || activePage === "data-governance-and-analytics#services-details-offerings" || activePage === "blockchain#services-details-offerings" || activePage === "cloud-engineering#services-details-offerings" ||activePage === "product-engineering#services-over-view" || activePage === "data-governance-and-analytics#services-over-view" || activePage === "blockchain#services-over-view" || activePage === "cloud-engineering#services-over-view" ? "text-[#BD5FFF] scale-[105%] font-[600]" : "text-[#FFFFFF]"} mb-[.4rem]`} href='/services'>
                                        Services
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center py-[.7rem] mx-[1.6rem]'>
                                    <a className={`nav-link text-base ${activePage === "industries" || activePage === "fintech" || activePage === "banking" || activePage === "supply-chain-and-retail-management" || activePage === "edutech" ? "text-[#BD5FFF] scale-[105%] font-[600]" : "text-[#FFFFFF]"} mb-[.4rem]`} href='/industries'>
                                        Industries
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center py-[.7rem] mx-[1.6rem]'>
                                    <a className={`nav-link text-base ${activePage === "contact-us" ? "text-[#BD5FFF] scale-[105%] font-[600]" : "text-[#FFFFFF]"} mb-[.4rem]`} href='/contact-us'>
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </nav>
    )
}

export default Navbar
