import React, { useState } from 'react'

function LatestInsightsNavbar({  }) {
    const [isSelected, setIsSelected] = useState(true)
    return (
        <div className='latest-insights-navbar flex justify-center items-center bg-[#F6F7FC] rounded-full w-full h-[6.5rem] drop-shadow-[0_1.1rem_1rem_rgba(58,118,247,0.2)]'>
            <button onClick={() => {setIsSelected(false)}} className='text-[2rem] font-[700] text-[#282828] focus-within:text-[#FFFFFF] focus-within:bg-[#6842E5] focus-within:rounded-[2.4rem] px-[4.2rem] h-[6.4rem]'>
                All
            </button>
            <button onClick={() => {setIsSelected(true)}} className={`text-[2rem] font-[400] text-[#535B63] ${ isSelected ? "text-[#FFFFFF] bg-[#6842E5] rounded-[2.4rem]" : ""} px-[4.2rem] h-[6.4rem]`}>
                Blog
            </button>
            <button onClick={() => {setIsSelected(false)}} className='text-[2rem] font-[400] text-[#535B63] focus-within:text-[#FFFFFF] focus-within:bg-[#6842E5] focus-within:rounded-[2.4rem] px-[4.2rem] h-[6.4rem]'>
                Whitepaper
            </button>
            <button onClick={() => {setIsSelected(false)}} className='text-[2rem] font-[400] text-[#535B63] focus-within:text-[#FFFFFF] focus-within:bg-[#6842E5] focus-within:rounded-[2.4rem] px-[4.2rem] h-[6.4rem]'>
                Newsletter
            </button>
            <button onClick={() => {setIsSelected(false)}} className='text-[2rem] font-[400] text-[#535B63] focus-within:text-[#FFFFFF] focus-within:bg-[#6842E5] focus-within:rounded-[2.4rem] px-[4.2rem] h-[6.4rem]'>
                Podcast
            </button>
            <button onClick={() => {setIsSelected(false)}} className='text-[2rem] font-[400] text-[#535B63] focus-within:text-[#FFFFFF] focus-within:bg-[#6842E5] focus-within:rounded-[2.4rem] px-[4.2rem] h-[6.4rem]'>
                News Event
            </button>
        </div>
    )
}

export default LatestInsightsNavbar
