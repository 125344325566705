import React from 'react'

function TechIconSec({ title, icons }) {
    return (
        <div className='tech-icon-sec flex flex-col justify-start items-start'>
            <h3 className='tech-icon-sec-heading text-4xl font-[700] text-[#5C33A5]'>
                {title}
            </h3>
            <div className='grid grid-cols-3 gap-[2rem] lg:gap-[4rem] mt-[2.2rem]'>
                {icons?.length > 0 ?
                    icons?.map((icon, i) => {
                        return (
                            <div key={i} className='tech-icon-sec-icon w-[6rem] h-[6rem] sm:w-[8rem] sm:h-[8rem] lg:w-[11rem] lg:h-[11rem]'>
                                <img 
                                    className='w-full h-full'
                                    src={icon}
                                />
                            </div>
                        )
                    })
                    :
                    null
                }
            </div>
        </div>
    )
}

export default TechIconSec
