import React from 'react'
import { IndustriesDetailsBannerImgs, LandingImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router-dom';

function IndustriesDetailsBanner() {

    const navigate = useNavigate(); 

    return (
        <div className='industries-details-banner w-full h-[50rem] xs:h-[42rem] relative'>
            <div className='industries-details-banner-content flex flex-col justify-center items-start container-lg h-full'>
                <h1 className='text-[4.5rem] font-[500] text-[#FFFFFF] w-[100%] sm:w-[90%] md:w-[80%] xl:w-[60%] z-20'>
                    <p>
                        {`Explore how modern Business Process\nServices can help accelerate growth for\nbanking clients.`}
                    </p>
                </h1>
                <div 
                    className='flex justify-start items-center text-xl font-[600] text-[#FFFFFF] mt-[4rem] cursor-pointer'
                    onClick={() => {navigate('/contact-us')}}
                >
                    <div className='w-[5.2rem] h-[4rem] mr-[2rem]'>
                        <img 
                            className='w-full h-full'
                            src={LandingImgs.HomeLandingActionIcon}
                        />
                    </div>
                    Get White Paper
                </div>
            </div>
            <div className='industries-details-banner-img absolute h-full top-0 right-0 z-10 opacity-50 xl:opacity-100'>
                <img 
                    className='w-full h-full'
                    src={IndustriesDetailsBannerImgs.IndustriesDetailsBannerImg}
                />
            </div>
        </div>
    )
}

export default IndustriesDetailsBanner
