import React from 'react'
import { HomeImgs } from '../../../static/constants/imgConstants/imgConstants'

function PageInfoFloating({ title, details }) {
    return (
        <div className="relative">
            <div className='page-info-floating container-lg bg-[#FFFFFF] rounded-[1rem] -mt-[7rem] shadow-[0_2.4rem_14.4rem_0_rgba(104,66,229,0.27)] relative'>
                <div className='flex flex-col justify-center items-center p-[6.4rem]'>
                    <h1 className='text-[4.5rem] text-[#3C436A] mb-[2rem] [@media(max-width:420px)]:text-[3.5rem]'>
                        {title}
                    </h1>
                    <p className='text-4xl text-[#404040] text-center [@media(max-width:420px)]:text-2xl'>
                        {details}
                    </p>
                </div>
            </div>
            <div className="absolute w-[20.5rem] h-[22.5rem] left-0 top-[22rem]">
                <img src={HomeImgs.leftSemiCircle} alt="" className="" />
            </div>
        </div>
    )
}

export default PageInfoFloating
