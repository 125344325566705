import React from 'react'
import TechIconSec from '../../common/TechIconSec/TechIconSec'
import { IndustriesDetailsTechIconSecImgs } from '../../../static/constants/imgConstants/imgConstants'

function IndustriesDetailsTechStack({ techStackSubTitle, techStackData }) {
    return (
        <div className='industries-details-tech-stack container-lg'>
            <h1 className='text-[5rem] text-[#5C33A5] font-[700] mt-[4.4rem]'>
                Tech Stack
            </h1>
            <p className='text-7xl text-[#585F69] font-[400] mt-[1rem] mb-[6rem]'>
                {techStackSubTitle}
            </p>
            <div className='grid grid-cols-2 lg:grid-cols-3 gap-x-[18rem] gap-y-[7rem]'>
                {
                    techStackData?.map((item, i) => {
                        return (
                            <TechIconSec 
                                title={item.title}
                                icons={item.icons}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesDetailsTechStack
