import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import CaseStudyCard from '../../common/Cards/CaseStudyCard'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { CaseStudyImgs } from '../../../static/constants/imgConstants/imgConstants';

const handleDragStart = (e) => e.preventDefault();

function CaseStudy() {
    const items = [
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg2}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
        <CaseStudyCard 
            CaseStudyImg={CaseStudyImgs.CaseStudyImg1}
            info={`UI Design . 02 May 2019`}
            title={`Leveraging AI And Digital Technology\nFor Chronic Care Management`}
        />,
    ];

    const responsive = {
        568: { items: 1 },
        1024: { items: 2 },
    };

    return (
        <div className='case-study h-[92rem] flex flex-col justify-center items-center'>
            <SectionHeaders
                contentPrimary="Case "
                contentSecondary="Study"
                classes="text-6xl font-[700] mb-[1.2rem]"
            />
            <h2 className='text-[4.5rem] text-[#3C436A] font-[300]'>
                Unlocking Success Stories Through Innovation!
            </h2>
            <div className='container-lg mt-[5rem]'>
                <AliceCarousel mouseTracking items={items} responsive={responsive}/>
            </div>
        </div>
    )
}

export default CaseStudy

// filter: drop-shadow(0px 4px 54px rgba(139, 201, 234, 0.40));
// 1x

// drop-shadow-[0_.4rem_5.4rem_rgba(139,201,234,0.40)]
