import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home/Home';
import Industries from './pages/Industries/Industries';
import IndustriesDetails from './pages/IndustriesDetails/IndustriesDetails';
import Services from './pages/Services/Services';
import ServicesDetails from './pages/ServicesDetails/ServicesDetails';
import ContactUs from './pages/ContactUs/ContactUs';
import './App.sass';
import './global.sass';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';


const TRACKING_ID = "G-355TLRV3DY"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route path="/" element={<Home /> } />
        <Route path="/industries" element={<Industries /> } />
        <Route path="/industries/:industriesType" element={<IndustriesDetails /> } />
        <Route path="/services" element={<Services /> } />
        <Route path="/services/:serviceType" element={<ServicesDetails /> } />
        <Route path="/contact-us" element={<ContactUs /> } />
      </Routes>
    </div>
  );
}

export default App;
