import React from 'react'
import { LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants'

function LatestInsightsCard({bgImg}) {
    return (
        <div className='latest-insights-card cursor-pointer flex flex-col justify-start items-start mx-[1.2rem] rounded-[1.2rem] shadow-[0_1.1rem_2.5rem_rgba(58,118,247,0.2)]'>
            <div className='card-img-top w-full h-[27.5rem]'>
                <img 
                    className='w-full h-full'
                    src={bgImg}
                />
            </div>
            <div className='card-content-bottom flex flex-col justify-between w-full h-[23.6rem] p-[2rem]'>
                <h1 className='text-2xl text-[#202020] font-[600]'>
                    Leveraging AI And Digital Technology For Chronic Care Management
                </h1>
                <div className='flex justify-start items-center '>
                    <div className='user-profile-pic w-[4.4rem] h-[4.4rem] rounded-full mr-3'>
                        <img 
                            className='w-full h-full rounded-full'
                            src={LatestInsightsImgs.UserProfilePicImg}
                        />
                    </div>
                    <h3 className='text-[#010001] text-xl font-[400]'>
                        Rusty Welch
                    </h3>
                </div>
                <hr />
                <div className='flex justify-between items-center '>
                    <div className='flex justify-start items-center '>
                        <div className='user-profile-pic w-[2.8rem] h-[2.1rem] rounded-full mr-3'>
                            <img 
                                className='w-full h-full'
                                src={LatestInsightsImgs.ActionIconBlueImg}
                            />
                        </div>
                        <h3 className='text-[#094CF8] text-sm font-[400]'>
                            Learn Blog
                        </h3>
                    </div>
                    <h3 className='text-[#535B63] text-base font-[400]'>
                        23-July-2023
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default LatestInsightsCard
