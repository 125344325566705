import React from 'react'
import { Helmet } from 'react-helmet';
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import HomeLandingSections from '../../components/common/LandingSections/HomeLandingSections'
import AboutUs from '../../components/main/AboutUs/AboutUs'
import ServicesSec from '../../components/main/ServicesSec/ServicesSec'
import IndustriesSec from '../../components/main/IndustriesSec/IndustriesSec'
import SecureWorldBanner from '../../components/common/SecureWorldBanner/SecureWorldBanner'
import ProcessModelSec from '../../components/main/ProcessModelSec/ProcessModelSec'
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial'

function Home() {
    return (
        <>
            <Helmet>
                <title>IT Solutions & Services | Fintech, Banking, EduTech | Infominez</title>
                <meta name="description" content="Infominez excels in delivering state-of-the-art IT solutions across Fintech, banking, supply chain, retail management, and Edu tech. Explore our services in product engineering, data analytics, blockchain, and cloud engineering to transform your business." />
                <meta name="keywords" content="IT solutions, Fintech, banking, supply chain, retail management, Edu tech, product engineering, data governance, analytics, blockchain, cloud engineering" />
                <meta name="author" content="Infominez Dev Team" />
                <meta property="og:title" content="IT Solutions & Services | Fintech, Banking, EduTech | Infominez" />
                <meta property="og:description" content="Infominez excels in delivering state-of-the-art IT solutions across Fintech, banking, supply chain, retail management, and Edu tech. Explore our services in product engineering, data analytics, blockchain, and cloud engineering to transform your business." />
                <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                <meta property="og:url" content="https://infominez.com/" />
                <meta name="twitter:title" content="IT Solutions & Services | Fintech, Banking, EduTech | Infominez" />
                <meta name="twitter:description" content="Infominez excels in delivering state-of-the-art IT solutions across Fintech, banking, supply chain, retail management, and Edu tech. Explore our services in product engineering, data analytics, blockchain, and cloud engineering to transform your business." />
                <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='home'>
                <PrimaryLayout 
                    showNavbar={true}
                    showFooterMain={true}
                    ShowFooterMini={true}
                    showContactUs={true}
                >
                    <HomeLandingSections 
                        heading={`Data Governance &\nAnalytics Services`}
                        subHeading={`Data Governance Unleashed. Analytics Services Empowering \nBusiness Evolution`}
                        actionTitle={`Get a Quote`}
                    />
                    <AboutUs />
                    <ServicesSec 
                        isServicesAlt={false}
                        subHeading={"Infominez: Empowering Competitive Edge through Technology"}
                    />
                    <IndustriesSec />
                    <SecureWorldBanner />
                    <ProcessModelSec />
                    <ClientTestimonial />
                </PrimaryLayout>
            </div>
        </>
    )
}

export default Home
