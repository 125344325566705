import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import LatestInsightsNavbar from '../Navbar/LatestInsightsNavbar'
import LatestInsightsCard from '../../common/Cards/LatestInsightsCard'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants';

const handleDragStart = (e) => e.preventDefault();

function LatestInsights() {
    
    const items = [
        <div className='grid grid-cols-3 gap-4'>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img1}
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard
                    bgImg={LatestInsightsImgs.Blog1Img2} 
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img3}
                />
            </div>
        </div>,
        <div className='grid grid-cols-3 gap-4'>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img1}
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img2}
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img3}
                />
            </div>
        </div>,
        <div className='grid grid-cols-3 gap-4'>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img1}
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img2}
                />
            </div>
            <div onDragStart={handleDragStart} className='latest-insights-cards flex flex-col justify-start items-center h-[54rem]'>
                <LatestInsightsCard 
                    bgImg={LatestInsightsImgs.Blog1Img3}
                />
            </div>
        </div>,
    ];

    return (
        <div onDragStart={handleDragStart} className='latest-insights relative'>
            <div className='contact-us-container flex flex-col justify-center items-center container-lg h-[94.5rem] '>
                <SectionHeaders 
                    contentPrimary="Latest "
                    contentSecondary="Insights"
                    classes="text-6xl font-[700] mb-[2.4rem]"
                />
                <LatestInsightsNavbar />
                <div className='w-full mt-[6.5rem]'>
                    <AliceCarousel mouseTracking items={items} />
                </div>
            </div>  
            <div className="w-[14.5rem] h-[14.5rem] right-0 top-[-23rem] absolute">
                <img src={LatestInsightsImgs.RightSemicircle} alt="" className="" />
            </div>
        </div>
    )
}

export default LatestInsights
