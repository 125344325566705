import React from 'react'
import { SecureWorldBannerImgs } from '../../../static/constants/imgConstants/imgConstants'

function SecureWorldBanner() {
    return (
        <div className='secure-world-banner w-full h-[40rem] md:h-[27.7rem] mb-12'>
            <div className='flex flex-col md:flex-row justify-around md:justify-between items-center container-lg h-full my-auto'>
                <div className='secure-world-logo cursor-pointer w-[51.7rem] h-[16.5rem] ml-4 mr-10' onClick={()=> window.open("https://www.secureworldme.com/")}>
                    <img 
                        className='w-full my-auto'
                        src={SecureWorldBannerImgs.SecureWorldLogoImg}
                    />
                </div>
                <div className='flex flex-col justify-center items-center md:items-start text-[#FFFFFF] mx-8'>
                    <h1 className='secure-world-heading-main text-[3.2rem] md:text-[3.8rem] lg:text-[4.5rem] font-[700] uppercase tracking-tight -mb-[1.6rem]'>
                        Strategic Partners
                    </h1>
                    <h1 className='secure-world-heading text-[2.2rem] md:text-[3rem] lg:text-[3.5rem] font-[500] my-[1.2rem] md:my-0'>
                        Reimagining Security with Smart Machines
                    </h1>
                    <h1 className='secure-world-subheading text-xl md:text-2xl lg:text-3xl text-[#C0C7ED] font-[400] leading-10 text-center md:text-left'>
                        Securing Tomorrow, Today: Where Smart Systems, Robotics, AI, and Machines Unite for Unparalleled Physical Security Excellence.
                    </h1>
                </div>
            </div>
            
        </div>
    )
}

export default SecureWorldBanner
