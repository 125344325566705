import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import PageLandingSection from '../../components/common/LandingSections/PageLandingSection'
import { AdvantageWithUsImgs, LandingImgs, PageInfoServicesImgs, ServicesPageImgs } from '../../static/constants/imgConstants/imgConstants';
import ServicesMiniNavbar from '../../components/main/Navbar/ServicesMiniNavbar';
import PageInfoServices from '../../components/common/PageInfo/PageInfoServices';
import ServicesDetailsOfferings from '../../components/main/ServicesDetailsOfferings/ServicesDetailsOfferings';
import AdvantageWithUs from '../../components/main/AdvantageWithUs/AdvantageWithUs';

function ServicesDetails() {

    const { serviceType } = useParams();
    const [servicesDetailedData] = useState([
        {
            "id": 1,      
            "type": 'product-engineering',
            "title": "Product Engineering",
            "LandingSecImg": ServicesPageImgs.ServicesPageBgImg1,
            "subTitle": "We engineer dreams into reality, turning ideas into exceptional products that redefine industries.",
            "pageInfoTitle" : "How Product unlocks perpetual relevance",
            "pageInfoSubTitle" : "Intuitive software adapts to evolving customer needs, modernizing your business for superior experiences and innovation at the speed of intuition.",
            "pageInfoImg": PageInfoServicesImgs.ServicesPageInfoImg2,
            "isPageInfoTextLight": false,
            "servicesHeading":"Our Product Engineering Services",
            "pageInfoImgTitle" : "Product that\npowers modern businesses",
            "pageInfoImgSubTitle" : "We offer a robust execution framework that\nharnesses the power of design.",
            "pageInfoText": "Our Product Engineering Services offer end-to-end solutions to transform your ideas into successful products. From conceptualization and design to development, testing, and support, our experienced team leverages cutting-edge technologies to deliver high-quality, innovative, and market-ready products. With a focus on efficiency and customer satisfaction, we help you accelerate your time-to-market and stay ahead in the competitive IT landscape.",
            "servicesSubHeading": "At Infominez, we offer a comprehensive range of product engineering services tailored to meet your unique needs and challenges. Our services include:",
            "engineeringService": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Product Development",
                    "text": "We excel in full product development, using state-of-the-art tech, agile methods, and clear communication to ensure your product works flawlessly, scales securely, and delivers user-friendly experiences.",
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "UI/UX Design ",
                    "text": "Our skilled designers craft intuitive, visually appealing user interfaces to boost engagement and satisfaction. Our goal is to provide seamless user experiences that foster customer loyalty.",
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Quality Assurance ",
                    "text": "We excel in full product development, using state-of-the-art tech, agile methods, and clear communication to ensure your product works flawlessly, scales securely, and delivers user-friendly experiences.",
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "DevOps and Continuous Integration",
                    "text": "Our skilled designers craft intuitive, visually appealing user interfaces to boost engagement and satisfaction. Our goal is to provide seamless user experiences that foster customer loyalty.",
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Maintenance and Support ",
                    "text": "Our commitment doesn't end with the product launch. We provide ongoing maintenance and support services to ensure your product stays up-to-date and responsive to changing market demands.  ",
                }
            ],
            "AdvantageWithUsAltBg": AdvantageWithUsImgs.AdvantageWithUsAltBgImg1,
            "advantagesWithUsSubHeading": "With years of experience in the industry, our team has honed their skills on a wide range of projects. You can trust us to bring a wealth of knowledge to your project, ensuring its success.",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "title": "Expertise and Experience",
                    "text": "With years of industry experience, our team brings a wealth of knowledge to ensure your project's success."
                },
                {
                    "id": 2,
                    "title": "Cost-Effective Solutions",
                    "text": "We value cost control, offering efficient development & competitive pricing to help you achieve your goals."
                },
                {
                    "id": 3,
                    "title": "Collaboration & Transparency",
                    "text": "We prfioritize open communication, keeping you informed & ensuring your vision is realized during development."
                },
                {
                    "id": 4,
                    "title": "Adaptive Project Delivery",
                    "text": "Our agility lets us adjust to evolving requirements, ensuring timely and budget-friendly results.. "
                },
                {
                    "id": 5,
                    "title": "Innovative Solutions ",
                    "text": "We're tech trendsetters, injecting innovation into every project to keep your product competitive and future-proof."
                },
            ],
            metaTitle: 'Product Engineering | Software Development | Infominez',
            metaDescription: "Infominez specializes in product engineering, offering a robust execution framework that harnesses the power of modern design to power businesses. Discover our innovative approach to product development.",
            metaKeyword: 'product engineering, modern businesses, execution framework, design, product development',
            metaURL: 'https://infominez.com/services/product-engineering',
        },
        {
            "id": 2,      
            "type": 'data-governance-and-analytics',
            "title": "Data Governance and Analytics",
            "LandingSecImg": ServicesPageImgs.ServicesPageBgImg2,
            "subTitle": "Unleash the data potential with our precise governance and innovative analytics, illuminating the path to informed decisions",
            "pageInfoTitle" : "How Product unlocks perpetual relevance",
            "pageInfoSubTitle" : "Intuitive software adapts to evolving customer needs, modernizing your business for superior experiences and innovation at the speed of intuition.",
            "pageInfoImg": PageInfoServicesImgs.ServicesPageInfoImg1,
            "isPageInfoTextLight": true,
            "servicesHeading":"Our Data Governance and Analytics Services",
            "pageInfoImgTitle" : "Empowering Insights, Driving Decisions ",
            "pageInfoImgSubTitle" : "We offer a robust execution framework that\nharnesses the power of design.",
            "pageInfoText": "Our Product Engineering Services offer end-to-end solutions to transform your ideas into successful products. From conceptualization and design to development, testing, and support, our experienced team leverages cutting-edge technologies to deliver high-quality, innovative, and market-ready products. With a focus on efficiency and customer satisfaction, we help you accelerate your time-to-market and stay ahead in the competitive IT landscape.",
            "servicesSubHeading": "Our comprehensive suite of data governance and analytics services is designed to empower organizations with actionable insights and ensure data integrity and compliance",
            "engineeringService": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Data Strategy & Consulting",
                    "text": "We work closely with you to define a data strategy aligned with your business objectives. Our expert consultants help you identify key data sources, define data governance policies, and establish a roadmap for leveraging data as a strategic asset. ",
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Data Integration & Management ",
                    "text": "Our data engineers ensure seamless integration of disparate data sources, enabling you to consolidate and centralize your data for easy access and analysis. We implement robust data management practices to maintain data quality and consistency. ",
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Data Analytics & Visualization",
                    "text": "Turn your data into actionable insights with our advanced analytics and visualization services. Our data scientists leverage state-of-the-art tools and techniques to extract valuable insights that drive informed decision-making. ",
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Business Intelligence",
                    "text": "Empower your teams with self-service business intelligence tools that enable them to explore data, create reports, and generate dashboards. Make data-driven decisions accessible to everyone in your organization. ",
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Data Governance & Compliance ",
                    "text": "We implement data governance frameworks to ensure data privacy, security, and compliance with regulatory requirements such as GDPR and HIPAA. Our solutions help you maintain data lineage, auditing, and access controls",
                }
            ],
            "AdvantageWithUsAltBg": AdvantageWithUsImgs.AdvantageWithUsAltBgImg2,
            "advantagesWithUsSubHeading": "With years of experience in the industry, our team has honed their skills on a wide range of projects. You can trust us to bring a wealth of knowledge to your project, ensuring its success.",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "title": "Data Expertise",
                    "text": "Our team comprises data technology experts bring extensive experience and precision to your initiatives."
                },
                {
                    "id": 2,
                    "title": "Innovative Solutions ",
                    "text": "Our analytics solutions transform raw data into actionable insights that drives strategic choice for a competitive edge. "
                },
                {
                    "id": 3,
                    "title": "Compliance Assurance",
                    "text": "We make data privacy and compliance a top priority in our governance, ensuring strict protection & compliance."
                },
                {
                    "id": 4,
                    "title": "Scalable Solutions",
                    "text": "Our solutions scale and adapt to your dynamic data needs and expanding analytics requirements."
                },
                {
                    "id": 5,
                    "title": "Faster Time-to-Insight",
                    "text": "Our streamlined data processes unlock rapid value extraction. Real-time analytics for agile market responses."
                },
            ],
            metaTitle: 'Data Governance & Analytics | Data Management Solutions | Infominez',
            metaDescription: "Infominez empowers businesses with data governance and analytics, providing insights that drive informed decisions. Explore our robust framework for harnessing the power of data.",
            metaKeyword: 'data governance, data analytics, business insights, informed decisions, data framework',
            metaURL: 'https://infominez.com/services/data-governance-and-analytics',
        },
        {
            "id": 3,      
            "type": 'blockchain',
            "title": "Blockchain",
            "LandingSecImg": ServicesPageImgs.ServicesPageBgImg2,
            "subTitle": "Transforming trust through blockchain innovation, we shape the way for secure and transparent transactions in a digital world",
            "pageInfoTitle" : "How Blockchain Innovation Pioneering the Future of Trust & Security",
            "pageInfoSubTitle" : "Blockchain innovation is leading decentralized and immutable ledger technology is revolutionizing industries by providing unparalleled data integrity and security.",
            "pageInfoImg": PageInfoServicesImgs.ServicesPageInfoImg1,
            "isPageInfoTextLight": true,
            "servicesHeading":"Our Services",
            "pageInfoImgTitle" : "Building Trust in a Digital World",
            "pageInfoImgSubTitle" : `Transformative blockchain solution empowers\ncontemporary enterprises by enhancing transparency,\nsecurity, and efficiency.`,
            "pageInfoText": "In the digital landscape of Banking, Fintech, Cryptocurrency, Cloud Services, and Innovative Security Systems utilising Smart Devices, Infominez serves as your beacon in blockchain technology. We integrate software products and services to revolutionise trust, security, and innovation through the power of blockchain",
            "servicesSubHeading": "Blockchain is the ultimate guardian of digital trust—a secure, transparent, and decentralized ledger that revolutionizes transactions and record-keeping across industries.",
            "engineeringService": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Blockchain Development:",
                    "text": "Designing, building, and deploying custom blockchain solutions.",
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Smart Contracts:",
                    "text": "Creating self-executing contracts for seamless, trustless transactions.",
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Blockchain Integration:",
                    "text": "Integrating blockchain into existing systems for enhanced security and transparency.",
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "Tokenization Solutions:",
                    "text": "Transforming assets into digital tokens for enhanced liquidity and accessibility.",
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Decentralized Apps (DApps):",
                    "text": "Developing decentralized applications for a wide range of industries.",
                }
            ],
            "AdvantageWithUsAltBg": AdvantageWithUsImgs.AdvantageWithUsAltBgImg1,
            "advantagesWithUsSubHeading": "With years of experience in the industry, our team has honed their skills on a wide range of projects. You can trust us to bring a wealth of knowledge to your project, ensuring its success.",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "title": "Blockchain Pioneers:",
                    "text": "We're early adopters and innovators in the blockchain space."
                },
                {
                    "id": 2,
                    "title": "Cross-Domain Expertise:",
                    "text": "Our team understands the unique needs of diverse industries."
                },
                {
                    "id": 3,
                    "title": "Security by Design:",
                    "text": "We prioritize the highest standards of blockchain security."
                },
                {
                    "id": 4,
                    "title": "Cost-Effective Solutions:",
                    "text": "We deliver blockchain solutions that maximize value for your business."
                },
                {
                    "id": 5,
                    "title": "Continuous Support:",
                    "text": "Our support extends beyond deployment, ensuring your blockchain ecosystem evolves with your needs."
                },
            ],
            metaTitle: 'Blockchain Services | Secure & Transparent Solutions | Infominez',
            metaDescription: "Infominez delivers transformative blockchain solutions, enhancing transparency, security, and efficiency for contemporary enterprises. Build trust in the digital age with our advanced blockchain technology.",
            metaKeyword: 'blockchain solutions, digital trust, transparency, security, efficiency, blockchain technology',
            metaURL: 'https://infominez.com/services/blockchain',
        },
        {
            "id": 4,      
            "type": 'cloud-engineering',
            "title": "Cloud Engineering",
            "LandingSecImg": ServicesPageImgs.ServicesPageBgImg2,
            "subTitle": "Advance your digital transformation journey with the support of our cloud services, achieve transformational change on a broad scale and at a rapid pace.",
            "pageInfoTitle" : "How Cloud Excellence is Your Pathway to Success.",
            "pageInfoSubTitle" : "Scalable, agile, and cost-effective solutions empowers businesses to innovate, streamline operations, and stay competitive in today's digital landscape.",
            "pageInfoImg": PageInfoServicesImgs.ServicesPageInfoImg1,
            "isPageInfoTextLight": true,
            "servicesHeading":"Our Services",
            "pageInfoImgTitle" : "Building Tomorrow's Cloud Solutions Today",
            "pageInfoImgSubTitle" : `Crafting Cloud Solutions That Drive Success.`,
            "pageInfoText": "In the digital galaxy of Banking, Fintech, Supply Chain/Retail, EduTech, Security Systems, and Smart Devices, Infominez is your launchpad to the cloud. With expertise in Cloud Engineering, we design, develop, and deploy innovative solutions that empower your business for a future of limitless possibilities.",
            "servicesSubHeading": "Cloud Engineering is the art of crafting scalable, secure, and performance-driven cloud solutions that drive digital transformation and success across industries.",
            "engineeringService": [
                {
                    "id": 1,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg1,
                    "title": "Cloud Solution Design:",
                    "text": "Architecting tailored cloud solutions that align with your business goals.",
                },
                {
                    "id": 2,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg2,
                    "title": "Cloud Infrastructure Development:",
                    "text": "Building robust cloud infrastructures that scale with your needs.",
                },
                {
                    "id": 3,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg3,
                    "title": "Cloud Migration and Integration:",
                    "text": "Seamlessly transitioning to the cloud and integrating with existing systems.",
                },
                {
                    "id": 4,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg4,
                    "title": "DevOps and Cloud Automation:",
                    "text": "Streamlining development, testing, and deployment with cloud-powered DevOps.",
                },
                {
                    "id": 5,
                    "icon": AdvantageWithUsImgs.AdvantageWithUsIconImg5,
                    "title": "Cloud Security and Compliance:",
                    "text": "Ensuring your cloud ecosystem is fortified with state-of-the-art security measures.",
                }
            ],
            "AdvantageWithUsAltBg": AdvantageWithUsImgs.AdvantageWithUsAltBgImg2,
            "advantagesWithUsSubHeading": "With years of experience in the industry, our team has honed their skills on a wide range of projects. You can trust us to bring a wealth of knowledge to your project, ensuring its success.",
            "advantagesWithUs": [
                {
                    "id": 1,
                    "title": "Industry Insights:",
                    "text": "Our team possesses deep knowledge across diverse sectors, ensuring tailored cloud solutions."
                },
                {
                    "id": 2,
                    "title": "Technology Pioneers:",
                    "text": "We stay at the forefront of cloud tech trends, delivering innovation to your doorstep."
                },
                {
                    "id": 3,
                    "title": "Security by Design:",
                    "text": "We prioritize the highest standards of cloud security to protect your data."
                },
                {
                    "id": 4,
                    "title": "Scalability and Performance:",
                    "text": "Our solutions are designed to scale with your business, ensuring peak performance."
                },
                {
                    "id": 5,
                    "title": "24/7 Cloud Support:",
                    "text": "Our dedicated team is always at your service, ensuring uninterrupted cloud operations."
                },
            ],
            metaTitle: 'Cloud Engineering | Cloud Solutions & Services | Infominez',
            metaDescription: "Infominez specializes in cloud engineering, providing scalable and secure cloud solutions. Empower your business with our cloud infrastructure expertise to drive efficiency and innovation.",
            metaKeyword: 'cloud engineering, cloud solutions, scalable infrastructure, secure cloud, business efficiency, innovation',
            metaURL: 'https://infominez.com/services/cloud-engineering',
        },
    ]);

    return (
        <div className='services-details'>
            <PrimaryLayout
                showNavbar={true}
                // ShowCaseStudy={true}
                // showLatestInsights={true}
                showContactUs={true}
                showFooterMain={true}
                ShowFooterMini={true}
            >
                {servicesDetailedData?.filter((service) => { return service.type === serviceType })?.length > 0 ?
                    servicesDetailedData?.filter((service) => { return service.type === serviceType })?.map((service, i) => {
                        return (
                            <>
                                <Helmet>
                                    <title>{service.metaTitle}</title>
                                    <meta name="description" content={service.metaDescription} />
                                    <meta name="keywords" content={service.metaDescription} />
                                    <meta name="author" content="Infominez Dev Team" />
                                    <meta property="og:title" content={service.metaTitle} />
                                    <meta property="og:description" content={service.metaDescription} />
                                    <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                                    <meta property="og:url" content={service.metaURL} />
                                    <meta name="twitter:title" content={service.metaTitle} />
                                    <meta name="twitter:description" content={service.metaDescription} />
                                    <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                                    <meta name="twitter:card" content="summary_large_image" />
                                </Helmet>
                                <PageLandingSection
                                    isDetailsPage
                                    heading={service.title}
                                    subHeading={service.subTitle}
                                    LandingSecImgs={service.LandingSecImg}
                                />
                                <ServicesMiniNavbar />
                                <PageInfoServices 
                                    isPageInfoTextLight={service.isPageInfoTextLight}
                                    pageInfoTitle={service.pageInfoTitle}
                                    pageInfoSubTitle={service.pageInfoSubTitle}
                                    pageInfoImg={service.pageInfoImg}
                                    pageInfoImgTitle={service.pageInfoImgTitle}
                                    pageInfoImgSubTitle={service.pageInfoImgSubTitle}
                                    pageInfoText={service.pageInfoText}
                                />
                                <ServicesDetailsOfferings 
                                    servicesHeading={service.servicesHeading}
                                    servicesSubHeading={service.servicesSubHeading}
                                    engineeringService={service.engineeringService}
                                />
                                <AdvantageWithUs 
                                    isAdvantageWithUsAlt
                                    advantagesWithUsSubHeading={service.advantagesWithUsSubHeading}
                                    advantagesWithUs={service.advantagesWithUs}
                                    AdvantageWithUsAltBg={service.AdvantageWithUsAltBg}
                                />
                            </>
                        )
                    })
                    :
                    null
                }
                {/* <IndustriesDetailsBanner />
                <IndustriesDetailsTechStack /> */}
            </PrimaryLayout>
        </div>
    )
}

export default ServicesDetails
