import React from 'react'
import { PageInfoServicesImgs } from '../../../static/constants/imgConstants/imgConstants'

function PageInfoServices({ pageInfoTitle, pageInfoSubTitle, pageInfoImg, pageInfoImgTitle, pageInfoImgSubTitle, pageInfoText, isPageInfoTextLight }) {
    return (
        <div id="services-over-view" className='page-info-services w-full'>
            <div className='page-info-services-heading-group container-lg flex flex-col justify-start items-start mt-[7rem] mb-[5rem]'>
                <h1 className='text-[5rem] text-[#5C33A5] font-[700] mb-[1rem]'>
                    {pageInfoTitle}
                </h1>
                <h1 className='text-7xl text-[#585F69] font-[400]'>
                    {pageInfoSubTitle}
                </h1>
            </div>
            <div className='page-info-services-img-group w-full relative'>
                <div className='page-info-services-img w-full h-[56rem]'>
                    <img 
                        className='w-full h-full'
                        src={pageInfoImg}
                    />
                </div>
                <div className='page-info-services-img-text flex flex-col justify-center items-start container-lg absolute'>
                    <pre className={`text-[3rem] md:text-[5rem] ${isPageInfoTextLight ? `text-[#FFFFFF]` : `text-[#5C33A5]` } font-[500] leading-[5rem]`}>
                        {pageInfoImgTitle}
                    </pre>
                    <pre className={`text-xl md:text-5xl ${isPageInfoTextLight ? `text-[#FFFFFF]` : `text-[#001736]` } font-[600] mt-[3rem]`}>
                        {pageInfoImgSubTitle}
                    </pre>
                </div>
            </div>
            <div className='w-full bg-[#F7F7FF]'>
                <p className='page-info-services-content container-lg text-2xl text-[#7A7F87] font-[400] text-left pt-[3.8rem] pb-[6.5rem]'>
                    {pageInfoText}
                </p>
            </div>
        </div>
    )
}

export default PageInfoServices
