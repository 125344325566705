import React from 'react'
import { LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router';

function IndustriesSecCard({ id, title, content, isSelected, iconLight, iconDark, setSelectId, path }) {
    const navigate = useNavigate(); 

    return (
        <div 
            className={`industries-sec-card cursor-pointer h-[48rem] lg:h-[52rem] flex flex-col justify-start items-start px-[3.2rem] py-[1.6rem] lg:py-[6rem] ${isSelected ? "bg-[#7445C9]" : "bg-[#FFFFFF]"} border-l-[.1rem] border-[#d6d6d6]`}
            onMouseEnter={() => setSelectId(id)}
            onMouseLeave={() => setSelectId(null)}
        > 
            <div className='services-card w-[6.8rem] h-[7rem] my-[1.6rem]'>
                {
                    isSelected ?
                        <img 
                            className='w-full h-full'
                            src={iconLight}
                        />
                        :
                        <img 
                            className='w-full h-full'
                            src={iconDark}
                        />
                }
            </div>
            <pre className={`text-3xl font-[600] ${isSelected ? "text-[#FFFFFF]" : "text-[#3C436A]"} text-left my-[1.6rem]`}>
                {title}
            </pre>
            <div className='flex justify-center items-center h-[.5rem] my-[2rem]'>
                <div className='w-[9rem] h-[.5rem] bg-[#00FFFF] mr-[.7rem] rounded-[.5rem]'></div>
                <div className={`w-[1rem] h-[.5rem]  ${isSelected ? "bg-[#EE83FF]" : "bg-[#7445C9]"} rounded-[.5rem]`}></div>
            </div>
            <h2 className={`text-lg font-[500] text-DM-Sans-sans-serif ${isSelected ? "text-[#FFFFFF]" : "text-[#707F96]"} text-left my-[1.6rem]`}>
                {content}
            </h2>
            <div className={`flex justify-center items-center my-[1rem] mt-auto text-sm text-[#094CF8] ${isSelected ? "bg-[#FFFFFF] px-[2rem] py-[.4rem] rounded-[2rem]" : ""}`}>
                <div className='w-[2.5rem] h-[2.1rem] mr-[1.2rem]'>
                    <img 
                        className='w-full h-full'
                        src={LatestInsightsImgs.ActionIconBlueImg}
                    />
                </div>
                <div className="" onClick={()=> navigate(path)}>
                    Learn More
                </div>
            </div>
        </div>
    )
}

export default IndustriesSecCard
