import React from 'react'

function PageInfoIndustries({ pageInfo, pageInfoImg }) {
    return (
        <div className='page-info-industries bg-[#FFFFFF] relative w-full mt-[2rem] sm:mt-[7.5rem]'>
            <div className='flex flex-col-reverse sm:flex-row justify-between items-center py-[6.4rem] container-lg'>
                <div className='page-info-industries-content flex flex-col justify-center items-start mr-[4rem]'>
                    {
                        pageInfo?.map((item, i) => {
                            return (
                                <p key={i} className='text-3xl text-[#404040] my-[1.2rem]'>{item}</p>
                            )
                        })
                    }
                </div>
                <div className='page-info-industries-img w-[50%] h-[90%]'>
                    <img 
                        className='w-full h-full'
                        src={pageInfoImg}
                    />
                </div>
            </div>
        </div>
    )
}

export default PageInfoIndustries
