import React from 'react'
import { BsCheckCircleFill } from "react-icons/bs";
import { IndustriesImgs, LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants';
import { useNavigate } from 'react-router-dom';

function IndustriesPageCard({ isReverse, icon, bgImg, path, title, content, features }) {

    const navigate = useNavigate(); 

    return isReverse ? (
        <div className='industries-page-card py-[2rem] container-lg relative'>
            <div className='flex flex-col justify-center items-start lg:mr-[50%] mt-[4rem] z-20'>
                <h2 className='text-9xl text-[#7445C9] font-[400] mb-[2rem] mt-[8rem]'>   
                    {title}
                </h2>
                <h2 className='text-4xl text-[#10417C] font-[400] mt-[1rem] mb-[3rem]'>
                    {content}
                </h2>
                {
                    features?.map((item, i) => {
                        return (
                            <div key={i} className='flex justify-start items-center my-[.8rem]'>
                                <BsCheckCircleFill className='mr-[1rem]' size={'2rem'} color='#7445C9'/>
                                <p className='text-3xl text-[#3C436A]'>
                                    {item}
                                </p>
                            </div>
                        )
                    })
                }
                <div 
                    className={`flex justify-center items-center cursor-pointer text-xl text-[#094CF8] bg-[#FFFFFF] my-[3rem] hover:scale-105 transition-transform`}
                    onClick={() => {navigate(path)}}
                >
                    <div className='w-[3.3rem] h-[2.6rem] mr-[1.2rem]'>
                        <img 
                            className='w-full h-full'
                            src={LatestInsightsImgs.ActionIconBlueImg}
                        />
                    </div>
                    Learn More
                </div>
            </div>
            <div className='industries-page-card-icon z-20 hidden lg:flex justify-center items-center rounded-[21rem] w-[21rem] h-[21rem] absolute right-[37.5%] [@media(min-width:1300px)]:right-[32%] top-[10.5rem] bg-[#444DFF] opacity-80 [@media(min-width:1300px)]:opacity-100 z-10'>
                <div className='w-[6rem] h-[6rem] [@media(min-width:1300px)]:w-[10rem] [@media(min-width:1300px)]:h-[10rem]'>
                    <img
                        className='w-full h-full'
                        src={icon}
                    />
                </div>
            </div>
            <div className='industries-page-card-img hidden lg:flex z-10 w-[70rem] h-[46rem] [@media(min-width:1300px)]:w-[81rem] [@media(min-width:1300px)]:h-[55rem] absolute right-[-12%] top-[20rem] z-10 opacity-50 [@media(min-width:1300px)]:opacity-100'>
                <img
                    className='w-full h-full'
                    src={bgImg}
                />
            </div>
        </div>
    ):
    (
        <div className='industries-page-card py-[2rem] container-lg relative'>
            <div className='industries-page-card-icon z-20 hidden lg:flex justify-center items-center rounded-[21rem] w-[21rem] h-[21rem] absolute left-[37.5%] [@media(min-width:1300px)]:left-[32%] top-[10.5rem] bg-[#444DFF] opacity-80 [@media(min-width:1300px)]:opacity-100 z-10'>
                <div className='w-[6rem] h-[6rem] [@media(min-width:1300px)]:w-[10rem] [@media(min-width:1300px)]:h-[10rem]'>
                    <img 
                        className='w-full h-full'
                        src={icon}
                    />
                </div>
            </div>
            <div className='industries-page-card-img hidden lg:flex z-10 w-[70rem] h-[46rem] [@media(min-width:1300px)]:w-[81rem] [@media(min-width:1300px)]:h-[55rem] absolute left-[-12%] top-[20rem] z-10 opacity-50 [@media(min-width:1300px)]:opacity-100'>
                <img 
                    className='w-full h-full'
                    src={bgImg}
                />
            </div>
            <div className='flex flex-col justify-center items-start lg:ml-[50%] mt-[4rem] z-20'>
                <h2 className='text-9xl text-[#7445C9] font-[400] mb-[2rem] mt-[8rem]'>
                    {title}
                </h2>
                <h2 className='text-4xl text-[#10417C] font-[400] mt-[1rem] mb-[3rem]'>
                    {content}
                </h2>
                {
                    features?.map((item, i) => {
                        return (
                            <div key={i} className='flex justify-start items-center my-[.8rem]'>
                                <BsCheckCircleFill className='mr-[1rem]' size={'2rem'} color='#7445C9'/>
                                <p className='text-3xl text-[#3C436A]'>
                                    {item}
                                </p>
                            </div>
                        )
                    })
                }
                <div 
                    className={`flex justify-center items-center cursor-pointer text-xl text-[#094CF8] bg-[#FFFFFF] my-[3rem] hover:scale-105 transition-transform`}
                    onClick={() => {navigate(path)}}
                >
                    <div className='w-[3.3rem] h-[2.6rem] mr-[1.2rem]'>
                        <img 
                            className='w-full h-full'
                            src={LatestInsightsImgs.ActionIconBlueImg}
                        />
                    </div>
                    Learn More
                </div>
            </div>
        </div>
    )
}

export default IndustriesPageCard
