import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import PageInfoFloating from '../../components/common/PageInfo/PageInfoFloating'
import PageLandingSection from '../../components/common/LandingSections/PageLandingSection'
import ServicePageCard from '../../components/common/Cards/ServicePageCard'
import ServicesSec from '../../components/main/ServicesSec/ServicesSec'
import { LandingImgs, ServicePageCardImgs } from '../../static/constants/imgConstants/imgConstants'

function Services() {
    const [data] = useState([
        {
            title: `Product Engineering\nServices`,
            content: "Our Product Engineering Services offer end-to-end solutions to transform your ideas into successful products. From conceptualization and design to development, testing, and support, our experienced team leverages cutting-edge technologies to deliver high-quality, innovative, and market-ready products. With a focus on efficiency and customer satisfaction, we help you accelerate your time-to-market and stay ahead in the competitive IT landscape.",
            subHeading:"Engineering Tomorrow's Innovations Today",
            icon: ServicePageCardImgs.ServicesPageInfoImg1,
            path: "/services/product-engineering",
            tileNumber: "01",
            isReverse: true
        },
        {
            title:`Data Governance &\nAnalytics Services`,
            content:"OurData Governance & Analytics Services empower businesses to harness the power of data for informed decision-making. With a comprehensive approach, we help organizationsestablish robust data governance frameworks, ensuring data quality, security, and compliance. Leveraging advanced analytics tools and techniques, we transform raw data into actionable insights, enabling you to unlock hidden opportunities, optimize operations, and drivebusiness growth. Trust our expertise to unleash the true potential of your data assets. ",
            subHeading:"Empowering Insights, Driving Decisions ",
            icon: ServicePageCardImgs.ServicesPageInfoImg2,
            path: "/services/data-governance-and-analytics",
            tileNumber: "02",
            isReverse: false
        },
        {
            title:"Blockchain Technology\nServices",
            content:"OurBlockchain Technology Services provide cutting-edge solutions to harness the power of blockchain technology for your business. From blockchain consulting and development to smart contract implementation and decentralized application (dApp) development, our team of experts ensures secure, transparent, and immutable transactions. Whether you're exploring blockchain adoption or need tailored solutions, we offer end-to-end services that drive innovation, streamline processes, and enhance trust in your digital ecosystem. Embrace the future of technology with our transformative blockchain services.",
            subHeading:"Building Trust in the Digital Age ",
            icon: ServicePageCardImgs.ServicesPageInfoImg3,
            path: "/services/blockchain",
            tileNumber: "03",
            isReverse: true
        },
        {
            title:"Cloud Engineering ",
            content:"Advance your digital transformation journey with the support of our cloud services, achieve transformational change on a broad scale and at a rapid pace.",
            subHeading:"Elevating Reality, Inspiring Innovation",
            icon: ServicePageCardImgs.ServicesPageInfoImg4,
            path: "/services/cloud-engineering",
            tileNumber: "04",
            isReverse: false
        }
    ])

    return (
        <>
            <Helmet>
                <title>IT Services | Customized Technology Solutions | Infominez</title>
                <meta name="description" content="Infominez excels in providing comprehensive IT services, including product engineering, data governance & analytics, blockchain technology, and cloud engineering. Discover our end-to-end solutions tailored to meet specific industry needs and drive business growth." />
                <meta name="keywords" content="IT Services, Product Engineering, Data Governance, Analytics Services, Blockchain Technology, Cloud Engineering, Industry Solutions, Software Development" />
                <meta name="author" content="Infominez Dev Team" />
                <meta property="og:title" content="IT Services | Customized Technology Solutions | Infominez" />
                <meta property="og:description" content="Infominez excels in providing comprehensive IT services, including product engineering, data governance & analytics, blockchain technology, and cloud engineering. Discover our end-to-end solutions tailored to meet specific industry needs and drive business growth." />
                <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                <meta property="og:url" content="https://infominez.com/services" />
                <meta name="twitter:title" content="IT Services | Customized Technology Solutions | Infominez" />
                <meta name="twitter:description" content="Infominez excels in providing comprehensive IT services, including product engineering, data governance & analytics, blockchain technology, and cloud engineering. Discover our end-to-end solutions tailored to meet specific industry needs and drive business growth." />
                <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='services'>
                <PrimaryLayout
                    showNavbar={true}
                    // showLatestInsights={true}
                    showContactUs={true}
                    showFooterMain={true}
                    ShowFooterMini={true}
                >
                    <PageLandingSection
                        heading={`Services`}
                        subHeading={`Cutting-Edge Solutions Tailored for You`}
                        LandingSecImgs={LandingImgs.ServicesLandingImg}
                    />
                    <PageInfoFloating
                        title={`Our Services`}
                        details={`As a comprehensive data and software development services provider, we specialize in catering  to various industries, offering a wide range of tailored solutions throughout the entire product  lifecycle. With our extensive expertise, we are well-equipped to deliver exceptional,  personalized solutions that precisely meet your specific industry requirements. Whether you  seek a dedicated industry solution or require outsourcing for development or testing tasks,  explore our industry-specific offerings to fulfill your business needs.`}
                    />
                    <ServicesSec
                        isServicesAlt={true}
                        subHeading={"End-to-end Development Services"}
                    />
                    {
                        data?.map((item)=> {
                            return(
                                <ServicePageCard 
                                    path={item.path}
                                    isReverse={item.isReverse}
                                    title={item.title}
                                    subHeading={item.subHeading}
                                    tileNumber={item.tileNumber}
                                    icon={item.icon}
                                    content={item.content}
                                />
                            )
                        })
                    }
                </PrimaryLayout>
            </div>
        </>
    )
}

export default Services
