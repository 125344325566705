import React from 'react'
import { ContactUsImgs } from '../../../static/constants/imgConstants/imgConstants'

function ContactUsOptionsSec() {

    const data = [
        {
            "id": 1,
            "icon": ContactUsImgs.ContactUsIcon1,
            "titleChar": "O",
            "titleText": "ffice",
            "contentText1": "253, Sai Kripa Colony ",
            "contentText2": "Indore - 452010, India",
        },
        {
            "id": 2,
            "icon": ContactUsImgs.ContactUsIcon2,
            "titleChar": "Call ",
            "titleText": "Us",
            "contentText1": "+91-982-606-1259",
            // "contentText2": "+91-982-606-1259",
        },
        {
            "id": 3,
            "icon": ContactUsImgs.ContactUsIcon3,
            "titleChar": "Send ",
            "titleText": "Us",
            "contentText1": "hello@infominez.com",
            // "contentText2": "carrer@infominez.com",
        },
    ]

    return (
        <div className='contact-us-options-sec container-lg grid grid-cols-1 lg:grid-cols-3 gap-[1rem] xl:gap-[2rem] my-[7rem] ml-[10rem] lg:mx-auto'>
            {
                data?.map((item, i) => {
                    return (
                        <div className='flex justify-start items-center'>
                            <div className='contact-us-options-icon w-[9.2rem] h-[9.2rem] mr-[4rem]'>
                                <img 
                                    className='w-full h-full'
                                    src={item.icon}
                                />
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <h1 className='text-[3rem] text-[#6842E5] font-[700]'>
                                    <span className='text-[#001736] inline-block'>{item.titleChar}</span>
                                    {item.titleText}
                                </h1>
                                <div className='flex flex-col justify-between items-start'>
                                    <h3 className='text-[2.5rem] text-[#3C436A]'>
                                        {item.contentText1}
                                    </h3>
                                    <h3 className='text-[2.5rem] text-[#3C436A]'>
                                        {item.contentText2}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ContactUsOptionsSec
