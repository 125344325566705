import React from 'react'

function CaseStudyCard({ info, title, CaseStudyImg }) {
    return (
        <div className='case-study-card cursor-pointer flex flex-col justify-between items-start m-[4rem] w-[96%] rounded-[.8rem] shadow-[0_.4rem_5.4rem_rgba(139,201,234,0.40)]'>
            <div className='case-study-card-img w-full h-[32.5rem]'>
                <img 
                    className='w-full h-full rounded-t-[.8rem]'
                    src={CaseStudyImg}
                />
            </div>
            <pre className='text-base text-[#545860] font-[400] m-[1.6rem]'>
                {info}
            </pre>
            <pre className='text-2xl text-[#202020] font-[400] w-[95%] pb-[1.6rem] border-b-[.4rem] border-b-[#2A98E3]/[.5] mb-[2.4rem] mx-[1.6rem]'>
                {title}
            </pre>
        </div>
    )
}

export default CaseStudyCard
