import React from 'react'
import Navbar from '../../main/Navbar/Navbar'
import LatestInsights from '../../main/LatestInsights/LatestInsights'
import CaseStudy from '../../main/CaseStudy/CaseStudy'
import ContactUs from '../../main/ContactUs/ContactUs'
import FooterMain from '../../common/Footer/FooterMain'
import FooterMini from '../../common/Footer/FooterMini'

function PrimaryLayout({ showNavbar, showFooterMain, ShowFooterMini, ShowCaseStudy, showLatestInsights, showContactUs, isContactUsAltBg, children }) {
    return (
        <div className='primary-layout overflow-x-hidden'>
            {showNavbar ? <Navbar /> : null }
            {children}
            {ShowCaseStudy ? <CaseStudy /> : null }
            {showLatestInsights ? <LatestInsights /> : null }
            {showContactUs ? <ContactUs isContactUsAltBg={isContactUsAltBg} /> : null }
            {showFooterMain ? <FooterMain /> : null }
            {ShowFooterMini ? <FooterMini /> : null }
        </div>
    )
}

export default PrimaryLayout
