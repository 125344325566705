import React from 'react'

function BtnOutlined({ title, bgColor, width, borderColor, titleColor, textSize, onClick }) {
    return (
        <button 
            className={`
                flex items-center justify-center
                border-2 rounded-full h-[5.2rem] 
                ${bgColor ? bgColor : "bg-[#FFFFFF] hover:bg-[#333333]"} 
                ${width ? width : "w-[15rem]"} 
                ${borderColor ? borderColor : "border-[#FFFFFF]"} 
                ${titleColor ? titleColor : "text-[#FFFFFF] hover:text-[#333333]"}
                ${textSize ? textSize : "text-[2rem]"}
            `} 
            onClick={onClick}
        >
            {title ? title : "Press me"}
        </button>
    )
}

export default BtnOutlined
