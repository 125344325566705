import React from 'react'

function InputGroup({ label, isRequired, value, setValue, inputType, inputPlaceholder, isFocused, isContactUsAltBg }) {
    return (
        <div className='input-group flex flex-col'>
            <label className='text-lg pb-[.8rem]'>
                {label ? label : "Enter"}
                {isRequired ? 
                    <span className='is-required text-[#FF2323]'>*</span>
                    :
                    null
                }
            </label>
            <div className={`w-full h-[5.9rem] ${isContactUsAltBg ? "bg-[#FFFFFF]/[.13] border-[#FFFFFF] focus-within:border-[#D74DFF]" : "bg-[#FFFFFF] border-[#476E84] focus-within:border-[#D74DFF]"} border-[.1rem] rounded-[.8rem]`}>
                <input 
                    className={`w-full h-full rounded-[.8rem] px-8 text-xl ${isContactUsAltBg ? "bg-[#FFFFFF]/[.13]" : "bg-[#FFFFFF]"}`}
                    type={inputType ? inputType : 'text'}
                    placeholder={inputPlaceholder}
                    autoFocus={isFocused}
                    value={value}
                    onChange={(e) => {
                        let nameReg = /[^a-z]/gi;
                        
                        console.log("label", label);
                        if(inputType === "number" && (e.target.value).length <= 10){
                            setValue(()=> e.target.value.replace(/[^0-9]/g, ""))
                        }else if(label === "Your name" && !nameReg.test(e.target.value)){
                            setValue(e.target.value);  
                        }
                        else if(label === "Company name"){
                            setValue(e.target.value);
                        }
                        else if(label === "Message"){
                            setValue(e.target.value);
                        }
                        else if(inputType === "email"){
                            setValue(e.target.value);
                        }
                        else{
                            return
                        }
                        setValue(e.target.value);
                    }}
                />
            </div>
        </div>
    )
}

export default InputGroup
