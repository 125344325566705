import React, { useState } from 'react'
import { ContactIconImgs } from '../../../static/constants/imgConstants/imgConstants'
import InputGroup from '../../common/InputGroup/InputGroup'
import { toast } from 'react-toastify';
import { handleContactUs } from '../../../services/contactUsServices';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';

function ContactUs({ isContactUsAltBg }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidMobile, setIsValidMobile] = useState(true);

    const gaEventTracker = useAnalyticsEventTracker('ContactUs');

    return (
        <div className={`contact-us py-[8rem] ${isContactUsAltBg ? "bg-contact-us-alt-bg contact-us-alt-bg bg-cover h-full w-full" : "bg-[#FAFCFF]/[.97]"} relative`}>
            <div className='contact-us-container flex flex-col justify-center items-center container-lg'>
                <h2 className={`section-heading text-center text-[5rem] ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#5C33A5]"} font-[700] mb-[1rem]`}>
                    Contact Us
                </h2>
                <h2 className={`section-subheading text-center text-[2.5rem] ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#000000]"} mb-[4.4rem]`}>
                    It will have a ‘Get in touch’ link to it
                </h2>
                <div className={`flex flex-col lg:flex-row justify-between items-center w-full ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#000000]"}`}>
                    <div className='contact-us-details flex flex-col justify-center items-start lg:mr-[10rem]'>
                        <h1 className='text-[3rem] font-[400] mb-[3rem] ml-4'>
                            Call Us or Fill the Form
                        </h1>
                        <div
                            className='flex justify-start items-center text-3xl mb-[2.2rem]'
                            onClick={() => { navigator.clipboard.writeText("+91-982-606-1259"); toast.success("copied to clipboard") }}
                        >
                            <div className='w-[6rem] mr-3'>
                                <div className='contact-us-icons w-[1.75rem] h-[2.9rem] mx-auto'>
                                    <img
                                        className='w-full h-full'
                                        src={isContactUsAltBg ? ContactIconImgs.CellPhoneIconWhiteImg : ContactIconImgs.CellPhoneIconBlackImg}
                                    />
                                </div>
                            </div>
                            +91-982-606-1259
                        </div>
                        <div className='flex justify-start items-center text-3xl mb-[2.2rem]'
                            onClick={() => window.location = "mailto:hello@infominez.com"}
                        >
                            <div className='w-[6rem] mr-3'>
                                <div className='contact-us-icons w-[3.4rem] h-[2.1rem] mx-auto'>
                                    <img
                                        className='w-full h-full'
                                        src={isContactUsAltBg ? ContactIconImgs.PostcardIconWhiteImg : ContactIconImgs.PostcardIconBlackImg}
                                    />
                                </div>
                            </div>
                            hello@infominez.com
                        </div>
                        <div
                            className='flex justify-start items-center text-3xl mb-[2.2rem]'
                            onClick={() => { window.open(`https://www.google.com/maps/place/Infominez+Pvt.+Ltd/@22.753558,75.9114961,17z/data=!3m1!4b1!4m6!3m5!1s0x39631d1355080bbd:0x1fb655ed1eb8f22e!8m2!3d22.7535531!4d75.914071!16s%2Fg%2F11vbyqyn92?entry=ttu`) }}
                        >
                            <div className='w-[6rem] mr-3'>
                                <div className='contact-us-icons w-[1.7rem] h-[2.5rem] mx-auto'>
                                    <img
                                        className='w-full h-full'
                                        src={isContactUsAltBg ? ContactIconImgs.LocationMarkerIconWhiteImg : ContactIconImgs.LocationMarkerIconBlackImg}
                                    />
                                </div>
                            </div>
                            <pre>
                                {`253, Sai Kripa Colony \n Indore - 452010 `}
                            </pre>
                        </div>
                    </div>
                    <div className='grow contact-us-form grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-20 w-full px-[2.4rem] xs:px-[4rem] sm:px-[12rem] lg:px-0'>
                        <InputGroup
                            label="Your name"
                            isRequired={true}
                            value={name}
                            setValue={setName}
                            inputType="text"
                            inputPlaceholder={''}
                            isContactUsAltBg={isContactUsAltBg}
                        // isFocused={true}
                        />
                        <InputGroup
                            label="Contact email"
                            isRequired={true}
                            value={email}
                            setValue={setEmail}
                            inputType="email"
                            inputPlaceholder={''}
                            isContactUsAltBg={isContactUsAltBg}
                        />
                        <InputGroup
                            label="Company name"
                            isRequired={true}
                            value={companyName}
                            setValue={setCompanyName}
                            inputType="text"
                            inputPlaceholder={''}
                            isContactUsAltBg={isContactUsAltBg}
                        />
                        <InputGroup
                            label="Phone Number"
                            isRequired={true}
                            value={number}
                            setValue={setNumber}
                            inputType="number"
                            inputPlaceholder={''}
                            isContactUsAltBg={isContactUsAltBg}
                        />
                        <InputGroup
                            label="Message"
                            isRequired={true}
                            value={message}
                            setValue={setMessage}
                            inputType="text"
                            inputPlaceholder={''}
                            isContactUsAltBg={isContactUsAltBg}
                        />
                        <button
                            className='w-full h-[5.9rem] bg-[#7445C9] rounded-[.8rem] mt-auto text-[#FFFFFF] text-lg font-[700] hover:bg-[#D74DFF]'
                            onClick={() => {
                                let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                                if (!name) {
                                    setIsValidName(false)
                                } else {
                                    setIsValidName(true)
                                }
                                if (!email) {
                                    setIsValidEmail(false)
                                } else {
                                    setIsValidEmail(true)
                                }
                                if (!number) {
                                    setIsValidMobile(false)
                                } else {
                                    setIsValidMobile(true)
                                }
                                if (!name) {
                                    console.log("Enter a valid name")
                                    toast.error("Enter a valid name")
                                    return;
                                }
                                if (!email) {
                                    console.log("Enter a valid email")
                                    toast.error("Enter a valid email")
                                    return;
                                }
                                if (!number) {
                                    console.log("Enter a valid mobile number")
                                    toast.error("Enter a valid mobile number")
                                    return;
                                }
                                if (!emailReg.test(email)) {
                                    toast.error("Enter a valid email")
                                    return;
                                }
                                if (number.length < 10) {
                                    console.log("Enter a valid mobile number")
                                    toast.error("Enter a valid mobile number")
                                    return;
                                }
                                handleContactUs({
                                    "fullName": name,
                                    "email": email,
                                    "contact": number,
                                    "subjectLine": "Manufacturing and Supply Chain",
                                    "message": message
                                })
                                setName('')
                                setEmail('')
                                setCompanyName('')
                                setNumber('')
                                setMessage('')

                                gaEventTracker('submit')
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-[14.5rem] h-[14.5rem] right-0 bottom-[2rem] absolute">
                <img src={ContactIconImgs.ContactUsEllipseImg} alt="" className="" />
            </div>
        </div>
    )
}

export default ContactUs
