import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import PageLandingSection from '../../components/common/LandingSections/PageLandingSection'
import PageInfoFloating from '../../components/common/PageInfo/PageInfoFloating'
import IndustriesPageCard from '../../components/common/Cards/IndustriesPageCard'
import { IndustriesImgs, LandingImgs } from '../../static/constants/imgConstants/imgConstants'

function Industries() {
    const [data] = useState([
        {
            title: "Fintech",
            content: "Shaping the Future of Financial Services Through Fintech Innovation and Transformation.",
            icon: IndustriesImgs.IndustriesIconImg1,
            bgImg: IndustriesImgs.IndustriesPageCardImg1,
            path: "/industries/fintech",
            isReverse: false,
            features: [
                "Fintech Solutions:",
                "Payment Processing",
                "Digital Banking",
                "Data Analytics",
                "Security & Compliance",
                "Customized Consultations",
            ],
        },
        {
            title: "Banking",
            content: "Revolutionizing Banking: Pioneering the Financial Future with Innovative Solutions and Seamless Services.",
            icon: IndustriesImgs.IndustriesIconImg2,
            bgImg: IndustriesImgs.IndustriesPageCardImg2,
            path: "/industries/banking",
            isReverse: true,
            features: [
                "Core Banking Solutions",
                "Digital Transformation",
                "Customer Experience",
                "Risk Management",
                "Payment Solutions",
            ],
        },
        {
            title: "Supply Chain & Retail Management",
            content: "Optimizing Supply Chains and Retail: Today's Solutions, Tomorrow's Success.",
            icon: IndustriesImgs.IndustriesIconImg3,
            bgImg: IndustriesImgs.IndustriesPageCardImg3,
            path: "/industries/supply-chain-and-retail-management",
            isReverse: false,
            features: [
                "Supply Chain Optimization",
                "Inventory Management",
                "Retail Analytics",
                "Demand Forecasting",
                "Vendor Management",
                "E-commerce Integration",
            ],
        },
        {
            title: "EduTech",
            content: "Empowering Tomorrow's Minds Today: Transforming Education with Innovative Edutech Solutions",
            icon: IndustriesImgs.IndustriesIconImg4,
            bgImg: IndustriesImgs.IndustriesPageCardImg4,
            path: "/industries/edutech",
            isReverse: true,
            features: [
                "Online Learning Platforms",
                "Student Management Systems",
                "Adaptive Learning",
                "LMS Integration",
                "Assessment Tools",
                "AI-Powered Learning",
            ],
        }
    ]);

    return (
        <>
            <Helmet>
                <title>Industry IT Solutions | Diverse Sector Expertise | Infominez</title>
                <meta name="description" content="Explore the wide range of industries Infominez serves with innovative IT solutions. From Fintech to EduTech, we empower businesses across sectors with cutting-edge technology." />
                <meta name="keywords" content="Industries, IT Solutions, Fintech, EduTech, Supply Chain, Retail Management, Banking, Technology Services" />
                <meta name="author" content="Infominez Dev Team" />
                <meta property="og:title" content="Industry IT Solutions | Diverse Sector Expertise | Infominez" />
                <meta property="og:description" content="Explore the wide range of industries Infominez serves with innovative IT solutions. From Fintech to EduTech, we empower businesses across sectors with cutting-edge technology." />
                <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                <meta property="og:url" content="https://infominez.com/industries" />
                <meta name="twitter:title" content="Industry IT Solutions | Diverse Sector Expertise | Infominez" />
                <meta name="twitter:description" content="Explore the wide range of industries Infominez serves with innovative IT solutions. From Fintech to EduTech, we empower businesses across sectors with cutting-edge technology." />
                <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='industries'>
                <PrimaryLayout
                    showNavbar={true}
                    // showLatestInsights={true}
                    showContactUs={true}
                    showFooterMain={true}
                    ShowFooterMini={true}
                >
                    <PageLandingSection
                        heading={`Industries`}
                        subHeading={`Empowering Progress Across Diverse Tech Sectors`}
                        LandingSecImgs={LandingImgs.IndustriesLandingImg}
                    />
                    <PageInfoFloating
                        title={`Industries We Serve`}
                        details={`As a comprehensive data and software development services provider, we specialize in catering  to various industries, offering a wide range of tailored solutions throughout the entire product  lifecycle. With our extensive expertise, we are well-equipped to deliver exceptional,  personalized solutions that precisely meet your specific industry requirements. Whether you  seek a dedicated industry solution or require outsourcing for development or testing tasks,  explore our industry-specific offerings to fulfill your business needs.`}
                    />
                    {
                        data?.map((item) => {
                            return(
                                <IndustriesPageCard
                                    title={item.title}
                                    content={item.content}
                                    icon={item.icon}
                                    bgImg={item.bgImg}
                                    path={item.path}
                                    isReverse={item.isReverse}
                                    features={item.features}
                                />
                            )
                        })
                    }
                </PrimaryLayout>
            </div>
        </>
    )
}

export default Industries
