import React, { useState } from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import IndustriesSecCard from '../../common/Cards/IndustriesSecCard'
import { IndustriesSecCardImgs } from '../../../static/constants/imgConstants/imgConstants';

function IndustriesSec() {
    const [selectId, setSelectId] = useState(null);
    const [data] = useState([
        {
            id: "1",
            // title: `Fintech\nand Banking`,
            title: `Fintech`,
            isSelected: false,
            content: `Shaping the Future of Financial Services Through Fintech Innovation and Transformation.`,
            iconLight: IndustriesSecCardImgs.IndustriesIcon1,
            iconDark: IndustriesSecCardImgs.IndustriesIconBlue1,
            path: "/industries/fintech"
        },
        {
            id: "2",
            // title: `Healthcare and\nWellness`,
            title: `Banking`,
            isSelected: false,
            content: `Revolutionizing Banking: Pioneering the Financial Future with Innovative Solutions and Seamless Services.`,
            iconLight: IndustriesSecCardImgs.IndustriesIcon2,
            iconDark: IndustriesSecCardImgs.IndustriesIconBlue2,
            path: "/industries/banking"
        },
        {
            id: "3",
            title: `Supply Chain & Retail\nManagement.`,
            isSelected: false,
            content: "Optimizing Supply Chains and Retail: Today's Solutions, Tomorrow's Success.",
            iconLight: IndustriesSecCardImgs.IndustriesIcon3,
            iconDark: IndustriesSecCardImgs.IndustriesIconBlue3,
            path: "/industries/supply-chain-and-retail-management"
        },
        {
            id: "4",
            // title: `Retail\n& E-Commerce `,
            title: `EduTech`,
            isSelected: false,
            content: "Empowering Tomorrow's Minds Today: Transforming Education with Innovative Edutech Solutions",
            iconLight: IndustriesSecCardImgs.IndustriesIcon4,
            iconDark: IndustriesSecCardImgs.IndustriesIconBlue4,
            path: "/industries/edutech"
        },
    ]);

    return (
        <div className='industries-sec py-[10rem] flex flex-col justify-center items-center'>
            <SectionHeaders 
                contentPrimary="Industries "
                contentSecondary="We Serve"
                classes="text-6xl font-[700] mb-[1.2rem]"
            />
            <h2 className='text-[4.5rem] text-[#3C436A] font-[300] text-center lg:text-left'>
                Elevating Industries with Precision and Innovation.
            </h2>
            <p className='text-3xl text-[#4E617C] font-[400] text-center my-[2rem] container-lg'>
                {`Our unwavering commitment to precision and innovation has consistently elevated industries, revolutionizing the way\nthey operate and succeed in a rapidly changing world.`}
            </p>
            <div className='container-lg grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 mt-[6rem] px-0 md:px-[4rem] lg:px-0 gap-[2rem] lg:gap-0'>
                {data?.length &&
                    data?.map((item)=> {
                        return(
                            <IndustriesSecCard 
                                key={item.id}
                                id={item.id}
                                isSelected={selectId === item.id ? true : false}
                                setSelectId={setSelectId}
                                title={item.title} 
                                content={item.content}
                                iconLight={item.iconLight}
                                iconDark={item.iconDark}
                                path={item.path}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesSec
