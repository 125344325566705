import React from 'react'
import { TestimonialImgs } from '../../../static/constants/imgConstants/imgConstants'
import TestimonyCard from '../../common/TestimonyCard/TestimonyCard'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

function ClientTestimonial() {
    const items = [
        <TestimonyCard 
            message={`Our development experience with Infominez has been fantastic.\nThey have adapted to each of our projects. When\nwe decide to take a project in a different direction\nthey efficiently change direction with us and\nprovide guidance and new milestones.`} 
            name='MR. JEETENDRA CHOUDHARY' 
            role='CMD, JC Ventures & JC Pay, Indore'
            image={TestimonialImgs.ClientImg1}
            imageWidth={"w-[40%] lg:w-[33%]"}
            linkedinLink={'https://in.linkedin.com/in/jeetendra-choudhary?original_referer=https%3A%2F%2Fwww.google.com%2F'} 
            twitterLink={'https://twitter.com/cmdjcventures?lang=en'}
            handleDragStart={handleDragStart}
        />,
        <TestimonyCard 
            message={`I was extremely happy with the team; I have been working with\nthem for a while, and their professionalism and patience was hugely\nadvantageous for us as we rolled out this complex project.\nThey are a robust organization that found the proper\nresources to meet our needs instead of forcing\na pre-engineered solution on us.\nI would and will rehire them.`} 
            name='Mr. Sandeep Mahimkar' 
            role='CEO, Litmus Information System LLP, Mumbai'
            image={TestimonialImgs.ClientImg2}
            imageWidth={"w-[45%] lg:w-[33%]"}
            linkedinLink={'https://in.linkedin.com/in/sandeep-mahimkar-674a44?trk=public_post_feed-actor-image'} 
            twitterLink={'https://twitter.com/Sandy_LITMUS_IT'}
            handleDragStart={handleDragStart}
        />,
        <TestimonyCard 
            message={`Under tight deadlines and with high expectations,\nInfominez was a pleasure to partner with..\nProfessional, conscientious and thoroughly\ncompetent - I wouldn't hesitate in recommending\nthem to other agencies`} 
            name='MR. Jarryd Osborne' 
            role='Senior Business Development Officer at 03E, Dallas'
            image={TestimonialImgs.ClientImg3}
            imageWidth={"w-[35%] lg:w-[27%]"}
            linkedinLink={'https://www.linkedin.com/in/jarryd-osborne-69a0b3a?challengeId=AQHso51x7wn_FAAAAYs9eLLFfBw8AXO895yzrV1YnObO7f0ZQZ3ramoRd3Db6NSEUtjiZvQ1ylSh-DAvgzPWfVIAaLSXK4Gpyg&submissionId=eae132e2-bae2-8e17-076f-78b2b9441648&challengeSource=AgFdd40W23Lm8wAAAYs9eX6z4gsSAauqvvfApQ4n2yHPxuakBKrRIqHyTWhi724&challegeType=AgGFecDegKPvgQAAAYs9eX627A41JJUhzZcqdypPit-o1Fl6KG3e0R0&memberId=AgF7ToCst0XSBQAAAYs9eX65J2tC6xz3KNu4C4R1EhdG7fc&recognizeDevice=AgGg92Z7MrHmjQAAAYs9eX68_Usg17y6mFJ8NFXXto5XQ_jx2fiO'} 
            twitterLink={'https://twitter.com/SMBkapital'}
            handleDragStart={handleDragStart}
        />,
    ];

    return (
        <div className="client-testimonial relative w-full h-[120rem] sm:h-[110rem] md:h-[90rem] overflow-hidden z-10">
            <div className='client-testimonial-bg top-0 bottom-0 left-0 right-0  h-full'>
                <img 
                    className='w-full h-full'
                    src={TestimonialImgs.TestimonialBgImg}
                    alt='bg-img'
                />
            </div>
            <div className='client-testimonial-container container-lg flex flex-col justify-between items-center absolute'>
                <div className='flex text-6xl font-[700] text-center'>
                    <h1 className='text-[#001736] mr-[1.2rem]'>
                        Client
                    </h1>
                    <h1 className='text-[#FFFFFF]'>
                        Testimonial
                    </h1>
                </div>
                <h2 className='text-[4.5rem] text-[#FFFFFF] font-[300]'>
                    What they say about Us
                </h2>
                <AliceCarousel autoPlayInterval={8000} infinite autoPlayStrategy='default' autoPlay mouseTracking items={items} />
            </div>
            
        </div>
    )
}

export default ClientTestimonial
