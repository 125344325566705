export const NavbarImgs = {
    InfominezLogoLight: require('../../assets/img/infominez-logo-light.png')
}

export const LandingImgs = {
    HomeLandingImg: require('../../assets/img/home-landing-img.jpg'),
    HomeLandingActionIcon: require('../../assets/img/home-landing-action-icon.png'),
    IndustriesLandingImg: require('../../assets/img/industries-landing-img.jpg'),
    ServicesLandingImg: require('../../assets/img/services-landing-img.jpg'),
    ContactUsLandingImg: require('../../assets/img/contact-us-landing-img.jpg'),
}

export const SocialIconImgs = {
    LinkedinIconWhiteImg: require('../../assets/icons/linkedin-icon-white.png'),
    FbIconWhiteImg: require('../../assets/icons/fb-icon-white.png'),
    TwitterIconWhiteImg: require('../../assets/icons/twitter-icon-white.png'),
    InstaIconWhiteImg: require('../../assets/icons/insta-icon-white.png'),
}

export const ContactIconImgs = {
    CellPhoneIconWhiteImg: require('../../assets/icons/cell-phone-icon-white.png'),
    LocationMarkerIconWhiteImg: require('../../assets/icons/location-marker-icon-white.png'),
    PostcardIconWhiteImg: require('../../assets/icons/postcard-icon-white.png'),
    CellPhoneIconBlackImg: require('../../assets/icons/cell-phone-icon-black.png'),
    LocationMarkerIconBlackImg: require('../../assets/icons/location-marker-icon-black.png'),
    PostcardIconBlackImg: require('../../assets/icons/postcard-icon-black.png'),
    ContactUsEllipseImg: require('../../assets/img/contact-us-ellipse-img.png'),
}

export const LatestInsightsImgs = {
    Blog1Img1: require('../../assets/img/blog-img-1.png'),
    Blog1Img2: require('../../assets/img/blog-img-2.png'),
    Blog1Img3: require('../../assets/img/blog-img-3.png'),
    UserProfilePicImg: require('../../assets/img/user-profile-pic.png'),
    ActionIconBlueImg: require('../../assets/img/action-icon-blue.png'),
    RightSemicircle: require('../../assets/img/right-semicircle.png'),
}

export const TestimonialImgs = {
    TestimonialBgImg: require('../../assets/img/testimonial-bg.png'),
    ClientImg1: require('../../assets/img/testimonial-client-img-1.png'),
    ClientImg2: require('../../assets/img/testimonial-client-img-2.png'),
    ClientImg3: require('../../assets/img/testimonial-client-img-3.png'),
    LeftQuadrant: require("../../assets/img/left-quadrant.png"),
}

export const ProcessModelSecImgs = {
    ProcessModelImg1: require('../../assets/img/process-model-img-1.png'),
    ProcessModelImg2: require('../../assets/img/process-model-img-2.png'),
    ProcessModelImg3: require('../../assets/img/process-model-img-3.png'),
    ProcessModelImg4: require('../../assets/img/process-model-img-4.png'),
    RightSemicircleBig: require('../../assets/img/right-semicircle-big.png'),
    
}

export const SecureWorldBannerImgs = {
    SecureWorldLogoImg: require('../../assets/img/secure-world-logo.png'),
}

export const AboutUsImgs = {
    AboutUsBgImg: require('../../assets/img/about-us-sec-bg.jpg'),
}

export const CaseStudyImgs = {
    CaseStudyImg1: require('../../assets/img/case-study-img-1.png'),
    CaseStudyImg2: require('../../assets/img/case-study-img-2.png'),
}

export const IndustriesSecCardImgs = {
    IndustriesIcon1: require("../../assets/icons/industries-icon-1.png"),
    IndustriesIcon2: require("../../assets/icons/industries-icon-2.png"),
    IndustriesIcon3: require("../../assets/icons/industries-icon-3.png"),
    IndustriesIcon4: require("../../assets/icons/industries-icon-4.png"),
    IndustriesIconBlue1: require("../../assets/icons/industries-icon-blue-1.png"),
    IndustriesIconBlue2: require("../../assets/icons/industries-icon-blue-2.png"),
    IndustriesIconBlue3: require("../../assets/icons/industries-icon-blue-3.png"),
    IndustriesIconBlue4: require("../../assets/icons/industries-icon-blue-4.png")
}

export const IndustriesImgs = {
    IndustriesPageCardImg1: require("../../assets/img/industries-page-card-img-1.jpg"),
    IndustriesPageCardImg2: require("../../assets/img/industries-page-card-img-2.jpg"),
    IndustriesPageCardImg3: require("../../assets/img/industries-page-card-img-3.jpg"),
    IndustriesPageCardImg4: require("../../assets/img/industries-page-card-img-4.jpg"),
    IndustriesIconImg1: require("../../assets/icons/industries-icon-1.png"),
    IndustriesIconImg2: require("../../assets/icons/industries-icon-2.png"),
    IndustriesIconImg3: require("../../assets/icons/industries-icon-3.png"),
    IndustriesIconImg4: require('../../assets/icons/industries-icon-4.png'),
}

export const PageInfoIndustriesImgs = {
    PageInfoIndustriesImg: require("../../assets/img/page-info-industries-img.png"),
    PageInfoIndustriesImg1: require("../../assets/img/page-info-industries-img1.jpg"),
    PageInfoIndustriesImg2: require("../../assets/img/page-info-industries-img2.jpg"),
    PageInfoIndustriesImg3: require("../../assets/img/page-info-industries-img3.jpg"),
    PageInfoIndustriesImg4: require("../../assets/img/page-info-industries-img4.jpg"),
}

export const IndustriesDetailsServiceOfferingsImgs = {
    IndustriesDetailsServiceOfferingsImg1: require("../../assets/img/industries-details-service-offerings-img-1.jpg"),
    IndustriesDetailsServiceOfferingsImg2: require("../../assets/img/industries-details-service-offerings-img-2.jpg"),
    IndustriesDetailsServiceOfferingsImg3: require("../../assets/img/industries-details-service-offerings-img-3.jpg"),
    IndustriesDetailsServiceOfferingsImg4: require("../../assets/img/industries-details-service-offerings-img-4.jpg"),
}

export const AdvantageWithUsImgs = {
    AdvantageWithUsIconImg1: require("../../assets/icons/advantage-with-us-icon-1.png"),
    AdvantageWithUsIconImg2: require("../../assets/icons/advantage-with-us-icon-2.png"),
    AdvantageWithUsIconImg3: require("../../assets/icons/advantage-with-us-icon-3.png"),
    AdvantageWithUsIconImg4: require("../../assets/icons/advantage-with-us-icon-4.png"),
    AdvantageWithUsIconImg5: require("../../assets/icons/advantage-with-us-icon-5.png"),
    AdvantageWithUsAltBgImg1: require("../../assets/img/advantage-with-us-alt-1.jpg"),
    AdvantageWithUsAltBgImg2: require("../../assets/img/advantage-with-us-alt-2.jpg"),
}

export const IndustriesDetailsBannerImgs = {
    IndustriesDetailsBannerImg: require("../../assets/img/industries-details-banner-img.png"),
}

export const IndustriesDetailsTechIconSecImgs = {
    TechIconImg1: require("../../assets/icons/tech/tech-icon-1.png"),
    TechIconImg2: require("../../assets/icons/tech/tech-icon-2.png"),
    TechIconImg3: require("../../assets/icons/tech/tech-icon-3.png"),
    TechIconImg4: require("../../assets/icons/tech/tech-icon-4.png"),
    TechIconImg5: require("../../assets/icons/tech/tech-icon-5.png"),
    TechIconImg6: require("../../assets/icons/tech/tech-icon-6.png"),
    TechIconImg7: require("../../assets/icons/tech/tech-icon-7.png"),
    TechIconImg8: require("../../assets/icons/tech/tech-icon-8.png"),
    TechIconImg9: require("../../assets/icons/tech/tech-icon-9.png"),
    TechIconImg10: require("../../assets/icons/tech/tech-icon-10.png"),
    TechIconImg11: require("../../assets/icons/tech/tech-icon-11.png"),
    TechIconImg12: require("../../assets/icons/tech/tech-icon-12.png"),
    TechIconImg13: require("../../assets/icons/tech/tech-icon-13.png"),
    TechIconImg14: require("../../assets/icons/tech/tech-icon-14.png"),
    TechIconImg15: require("../../assets/icons/tech/tech-icon-15.png"),
    TechIconImg16: require("../../assets/icons/tech/tech-icon-16.png"),
    TechIconImg17: require("../../assets/icons/tech/tech-icon-17.png"),
    TechIconImg18: require("../../assets/icons/tech/tech-icon-18.png"),
    TechIconImg19: require("../../assets/icons/tech/tech-icon-19.png"),
    TechIconImg20: require("../../assets/icons/tech/tech-icon-20.png"),
    TechIconImg21: require("../../assets/icons/tech/tech-icon-21.png"),
    TechIconImg22: require("../../assets/icons/tech/tech-icon-22.png"),
    TechIconImg23: require("../../assets/icons/tech/tech-icon-23.png"),
    TechIconImg24: require("../../assets/icons/tech/tech-icon-24.png"),
    TechIconImg25: require("../../assets/icons/tech/tech-icon-25.png"),
    TechIconImg26: require("../../assets/icons/tech/tech-icon-26.png"),
    TechIconImg27: require("../../assets/icons/tech/tech-icon-27.png"),
    TechIconImg28: require("../../assets/icons/tech/tech-icon-28.png"),
    TechIconImg29: require("../../assets/icons/tech/tech-icon-29.png"),
    TechIconImg30: require("../../assets/icons/tech/tech-icon-30.png"),
}

export const PageInfoServicesImgs = {
    ServicesPageInfoImg1: require("../../assets/img/services-page-info-img-1.jpg"),
    ServicesPageInfoImg2: require("../../assets/img/services-page-info-img-2.jpg"),
}

export const ServicePageCardImgs = {
    ServicesPageInfoImg1: require("../../assets/img/service-page-card-img-1.png"),
    ServicesPageInfoImg2: require("../../assets/img/service-page-card-img-2.png"),
    ServicesPageInfoImg3: require("../../assets/img/service-page-card-img-3.png"),
    ServicesPageInfoImg4: require("../../assets/img/service-page-card-img-4.png"),
}

export const ServicesPageImgs = {
    ServicesPageBgImg1: require("../../assets/img/services-page-img1.jpg"),
    ServicesPageBgImg2: require("../../assets/img/services-page-img2.jpg"),
    rightSemiCircleSmall: require("../../assets/img/right-semicircle-small.png"),
}

export const EndToEndDevelopmentServicesImgs = {
    EndToEndDevelopmentServicesImg1: require("../../assets/icons/end-to-end-development-services-1.png"),
    EndToEndDevelopmentServicesImg2: require("../../assets/icons/end-to-end-development-services-2.png"),
    EndToEndDevelopmentServicesImg3: require("../../assets/icons/end-to-end-development-services-3.png"),
    EndToEndDevelopmentServicesImg4: require("../../assets/icons/end-to-end-development-services-4.png"),
}

export const TestimonyCardImgs = {
    QuotationImg1: require("../../assets/icons/quotation-icons-1.png"),
    QuotationImg2: require("../../assets/icons/quotation-icons-2.png"),
}

export const ContactUsImgs = {
    ContactUsIcon1: require("../../assets/icons/contact-us-icon-1.png"),
    ContactUsIcon2: require("../../assets/icons/contact-us-icon-2.png"),
    ContactUsIcon3: require("../../assets/icons/contact-us-icon-3.png"),
    ContactUsMap: require("../../assets/img/contact-us-map.png"),
    ContactUsBgImg: require("../../assets/img/contact-us-bg-img.jpg"),
}

export const HomeImgs = {
    leftSemiCircle: require("../../assets/img/left-semicircle.png"),
}