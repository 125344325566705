import React from 'react'

function PageLandingSection({  isDetailsPage, heading, subHeading, LandingSecImgs }) {
    return (
        <>
            <div id='page-landing-section' className='page-landing-section relative w-full h-[57rem] z-10'>
                <div className={`page-landing-sections-bg top-0 bottom-0 left-0 right-0 h-full ${!isDetailsPage && "mt-[10rem]"}`}>
                    <img 
                        className='w-full h-full'
                        src={LandingSecImgs}
                        alt='bg-img'
                    />
                </div>
                <div className={`page-landing-sections-content position-absolute-center container-lg flex flex-col justify-center items-center absolute ${isDetailsPage && "mt-[10rem]"}`}>
                    <h1 className='page-landing-sections-heading text-[4rem] sm:text-[6rem] lg:text-[7.6rem] text-[#FFFFFF] text-center mb-[1rem]'>
                        <pre className={`text-Montserrat-sans-serif font-[300] tracking-tighter leading-[10rem] ${!isDetailsPage && "uppercase"}`}>
                            {heading ? heading : 'heading'}
                        </pre>
                    </h1>
                    <h1 className={`page-landing-sections-heading font-[300] text-[#FFFFFF] text-center mb-[2rem] ${isDetailsPage ? "text-[1.8rem] sm:text-[2.7rem] lg:text-[3.5rem]" : "text-[3rem] sm:text-[4.2rem] lg:text-[5rem]"}`}>
                        {subHeading ? subHeading : 'subHeading'}
                    </h1>
                </div>
            </div>
            {/* <div ref={bottomref}/>  */}
        </>
    )
}

export default PageLandingSection
