import React from 'react'
import { Helmet } from 'react-helmet'
import { LandingImgs } from '../../static/constants/imgConstants/imgConstants'
import PrimaryLayout from '../../components/layout/PrimaryLayout/PrimaryLayout'
import PageLandingSection from '../../components/common/LandingSections/PageLandingSection'
import PageInfoFloating from '../../components/common/PageInfo/PageInfoFloating'
import ContactUsOptionsSec from '../../components/common/ContactUsOptionsSec/ContactUsOptionsSec'
import DropUsALine from '../../components/common/DropUsALine/DropUsALine'

function ContactUs() {
    return (
        <>
            <Helmet>
                <title>Contact Us | Get in Touch for IT Solutions | Infominez</title>
                <meta name="description" content="Reach out to Infominez for any inquiries or feedback. Our dedicated team is ready to assist you and ensure your experience with our IT solutions is seamless and productive." />
                <meta name="keywords" content="contact Infominez, customer support, IT solutions assistance, feedback, inquiries" />
                <meta name="author" content="Infominez Dev Team" />
                <meta property="og:title" content="Contact Us | Get in Touch for IT Solutions | Infominez" />
                <meta property="og:description" content="Reach out to Infominez for any inquiries or feedback. Our dedicated team is ready to assist you and ensure your experience with our IT solutions is seamless and productive." />
                <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                <meta property="og:url" content="https://infominez.com/contact-us" />
                <meta name="twitter:title" content="Contact Us | Get in Touch for IT Solutions | Infominez" />
                <meta name="twitter:description" content="Reach out to Infominez for any inquiries or feedback. Our dedicated team is ready to assist you and ensure your experience with our IT solutions is seamless and productive." />
                <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className='contact-us'>
                <PrimaryLayout 
                    showNavbar={true}
                    showFooterMain={true}
                    ShowFooterMini={true}
                    showContactUs={true}
                    isContactUsAltBg={true}
                >
                    <PageLandingSection
                        heading={`Contact Us`}
                        subHeading={`we are happy to hear you round the clock by any means`}
                        LandingSecImgs={LandingImgs.ContactUsLandingImg}
                    />
                    <PageInfoFloating
                        title={`Get In Touch With Us`}
                        details={`We value your input and look forward to hearing from you. Whether you have questions, feedback, or simply want to get in touch, our team is here to assist you. Please feel free to reach out through the contact form below, and we will do our best to respond promptly. Your thoughts and inquiries are important to us as we strive to enhance your experience and provide the best possible service. Thank you for considering us as your point of contact, and we are excited to connect with you.`}
                    />
                    <ContactUsOptionsSec />
                    <DropUsALine />
                </PrimaryLayout>
            </div>
        </>
    )
}

export default ContactUs
