import React from 'react'
import { Helmet } from 'react-helmet'
import { AboutUsImgs, HomeImgs } from '../../../static/constants/imgConstants/imgConstants'
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined'

function AboutUs() {
    const activePage = window.location.href.split("#")[window.location.href.split("#").length - 1];

    return (
        <>
            {/* {activePage === "about-us" ?
                <Helmet>
                    <title>About Infominez - Pioneers in Diverse IT Solutions</title>
                    <meta name="description" content="Learn about Infominez, a dynamic IT firm excelling in Fintech, banking, supply chain, retail management, and Edu tech. Discover our commitment to innovation and excellence in product engineering, data analytics, blockchain, and cloud engineering." />
                    <meta name="keywords" content="About Infominez, IT solutions, Fintech, banking, supply chain, retail management, Edu tech, innovation, product engineering, data analytics, blockchain, cloud engineering" />
                    <meta name="author" content="Infominez Dev Team" />
                    <meta property="og:title" content="About Infominez - Pioneers in Diverse IT Solutions" />
                    <meta property="og:description" content="Learn about Infominez, a dynamic IT firm excelling in Fintech, banking, supply chain, retail management, and Edu tech. Discover our commitment to innovation and excellence in product engineering, data analytics, blockchain, and cloud engineering." />
                    <meta property="og:image" content="../../../public/infominez_logo_sm.png" />
                    <meta property="og:url" content="https://infominez.com/#about-us" />
                    <meta name="twitter:title" content="About Infominez - Pioneers in Diverse IT Solutions" />
                    <meta name="twitter:description" content="Learn about Infominez, a dynamic IT firm excelling in Fintech, banking, supply chain, retail management, and Edu tech. Discover our commitment to innovation and excellence in product engineering, data analytics, blockchain, and cloud engineering." />
                    <meta name="twitter:image" content="../../../public/infominez_logo_sm.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                :
                null
            } */}
            <div id="about-us" className='about-us about-us-bg w-full h-[70rem] sm:h-[63rem] md:h-[84rem] lg:h-[63rem] bg-[#F7F7FF] relative'>
                <div className='container-lg flex justify-between items-center h-full my-auto'>
                    <div className='about-us-content flex flex-col justify-between items-start w-full md:w-[60%]'>
                        <div className={`process-model-sec-header flex justify-start items-start text-6xl font-[700]`}>
                            <h1 className='text-[#001736] mr-[1.2rem]'>
                                About
                            </h1>
                            <h1 className='text-[#6842E5]'>
                                Us
                            </h1>
                        </div>
                        <h1 className='text-[#3C436A] text-[4.5rem] font-[300] mb-[3rem]'>
                            Our Capabilities
                        </h1>
                        <p className='text-xl font-400 text-[#4B4B4B] my-4'>
                            At Infominez, we are a dynamic IT firm specialising in delivering state-of-the-art technology solutions to a diverse range of industries. With a strong focus on FINTECH, E-PAYMENTS, Cryptocurrency, Data Analytics, Cloud Services, SAP, and Innovative Security Solutions integrated through SMART DEVICES, our expertise is unparalleled. We offer a comprehensive suite of services, including product engineering, data governance and analytics, blockchain, and cloud engineering. Our commitment to innovation and excellence propels us to assist businesses in thriving within an ever-evolving digital landscape. Explore how Infominez can empower your organisation to achieve new heights with our cutting-edge solutions and industry expertise
                        </p>
                        {/* <BtnOutlined
                            title="Learn More  →"
                            width="w-[17rem] mt-[3rem]"
                            textSize="text-base text-[600]"
                            titleColor="text-[#7445C9] hover:text-[#FFFFFF]"
                            borderColor="border-[#BD5FFF]"
                            bgColor={"bg-[#FFFFFF] hover:bg-[#BD5FFF]"}
                        /> */}
                    </div>
                </div>
                <div className="absolute w-[16rem] h-[16rem] lg:w-[33.5rem] lg:h-[33.7rem] right-0 left-0 -bottom-[8.5rem] lg:bottom-[-17rem] hidden md:flex">
                    <img src={HomeImgs.leftSemiCircle} alt="" className="" />
                </div>
            </div>
        </>
    )
}

export default AboutUs
