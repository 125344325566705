import React from 'react'
import { BsCheckCircleFill } from "react-icons/bs";

function IndustriesDetailsServiceOfferings({ serviceOfferings, serviceOfferingsImg }) {
    return (
        <div
            className='industries-details-service-offerings w-full py-[4rem]'
            style={{
                backgroundImage: `url(${serviceOfferingsImg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            {/* <div className='industries-details-service-offerings-bg w-full h-full'>
                <img 
                    className='w-full h-full'
                    src={serviceOfferingsImg}
                    alt='bg-img'
                />
            </div> */}
            <div className='industries-details-service-offerings-content container-lg flex flex-col justify-center items-start'>
                <h1 className='text-[5rem] text-[#FFFFFF] font-[700] mb-[7.5rem]'>
                    Service Offerings
                </h1>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[4rem] [@media(min-width:1300px)]:gap-x-[10rem] gap-y-[2rem] [@media(min-width:1300px)]:gap-y-[4rem]'>
                    {
                        serviceOfferings?.map((item, i) => {
                            return (
                                <div className='flex justify-start items-start'>
                                    <div className="w-[2.8rem] mr-[1.6rem] mt-[1rem]">
                                        <BsCheckCircleFill size={'2.8rem'} color='#D74DFF' />
                                    </div>
                                    <div className='flex justify-start items-start'>
                                        <h5 className='text-xl font-[400] text-[#FFFFFF]'>
                                            <h5 className='text-xl font-[400] text-[#2CEBFD] inline-block'>
                                                {item.title}
                                            </h5>
                                            {" "}{item.text}
                                        </h5>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default IndustriesDetailsServiceOfferings
