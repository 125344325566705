import { toast } from "react-toastify";

export const handleContactUs = async (payload) => {
    await fetch('https://infominez.com/api/contactUs', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json',
        },
    })
    .then((response) => {
        console.log(response);
        toast.success("Thanks For Contacting Us")
        return response.json()
    })
    .catch((err) => {
        console.log(err.message);
        return err
    });
};