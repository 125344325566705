import React from 'react'
import { LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router-dom';

function ServicePageCard({ isReverse, path, icon, title, content, subHeading, tileNumber }) {

    const navigate = useNavigate(); 

    return isReverse ? (
        <div className='service-page-card py-[8rem] container-lg relative'>
            <div className='grid grid-cols-1 lg:grid-cols-2'>
                <div className='hidden lg:flex service-page-card-img px-[10rem]'>
                    <img 
                        className='w-full'
                        src={icon}
                    />
                </div>
                <div className='flex flex-col justify-center items-start px-[6rem]'>
                    <div className='flex justify-start items-center mb-[4rem]'>
                        <div className='text-[4rem] text-[#3C436A] font-[700]'>
                            {tileNumber}
                        </div>
                        <div className='bg-[#BE5EFE] h-[11rem] w-[1rem] rounded-[1rem] mx-[2rem]'>.</div>
                        <pre className='text-[4rem] text-[#7445C9] font-[400] leading-[4.4rem]'>
                            {title}
                        </pre>
                    </div>
                    <h1 className='text-[3rem] text-[#3C436A] font-[400] mb-[2rem]'>
                        {subHeading}
                    </h1>
                    <p className='text-[2rem] text[#191919] font-[400]'>
                        {content}
                    </p>
                    <div 
                        className='flex justify-start items-center text-[2rem] text-[#094CF8] font-[400] cursor-pointer'
                        onClick={() => {navigate(path)}}
                    >
                        <div className='w-[3rem] h-[2.1rem] mr-[1rem] my-[3rem]'>
                            <img 
                                className='w-full'
                                src={LatestInsightsImgs.ActionIconBlueImg}
                            />
                        </div>
                        Learn More
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className='service-page-card w-full  bg-[#F7F7FF]'>
            <div className='py-[8rem] container-lg relative  bg-[#F7F7FF]'>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div className='flex flex-col justify-center items-start px-[6rem]'>
                        <div className='flex justify-start items-center mb-[4rem]'>
                            <div className='text-[4rem] text-[#3C436A] font-[700]'>
                                {tileNumber}
                            </div>
                            <div className='bg-[#BE5EFE] h-[11rem] w-[1rem] rounded-[1rem] mx-[2rem]'>.</div>
                            <pre className='text-[4rem] text-[#7445C9] font-[400] leading-[4.4rem]'>
                                {title}
                            </pre>
                        </div>
                        <h1 className='text-[3rem] text-[#3C436A] font-[400] mb-[2rem]'>
                            {subHeading}
                        </h1>
                        <p className='text-[2rem] text[#191919] font-[400]'>
                            {content}
                        </p>
                        <div 
                            className='flex justify-start items-center text-[2rem] text-[#094CF8] font-[400] cursor-pointer'
                            onClick={() => {navigate(path)}}
                        >
                            <div className='w-[3rem] h-[2.1rem] mr-[1rem] my-[3rem]'>
                                <img 
                                    className='w-full h-full'
                                    src={LatestInsightsImgs.ActionIconBlueImg}
                                />
                            </div>
                            Learn More
                        </div>
                    </div>
                    <div className='hidden lg:flex service-page-card-img px-[10rem]'>
                        <img 
                            className='w-full h-full'
                            src={icon}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicePageCard
